import { useEffect, useState } from 'react'
// Translation
import Locale from 'translations';
// Components
import SelectField from 'components/Form/SelectField/SelectField'
import TextField from 'components/Form/TextField/TextField'
// Context
import { useSBSState } from 'context/global'
// Assets
import { ReactComponent as AddIcon } from "assets/images/serviceBuilder/orange_add.svg";
import { ReactComponent as TrashIcon } from "assets/images/serviceBuilder/trash.svg";
// -----------------------------------------------------------------------------------------------------

// ** lookups
const SAR_OBJ = {
  id: 95,
  name: "Saudi Riyal",
  currency_code: "SAR",
}

const HotelPricesTaxes = ({ onChange }) => {

  const { Service_Builder, } = Locale;

  const [showTaxes, setShowTaxes] = useState(true)

  const [data, setData] = useState({
    nights: "",
    child_price: "",
    serviceCurrency: SAR_OBJ,
    price_type: '',
    rooms: [
      {
        room_type: '',
        adult_price: '',
        child_price: ''
      },
    ],
    taxes: [
      {
        name: '',
        type: '',
        value: '',
      },
    ]
  });

  const { allCurrencies, allLookupsBuilder } = useSBSState()

  const handleRoomType = (e, index, name) => {
    // const { value } = e.target
    const selectedItem = data.rooms[index]
    // // update selectedItem Name
    selectedItem[`${name}`] = e?.id
    // replace the item with updated name
    data.rooms[index] = selectedItem
    setData({ ...data, rooms: data.rooms })
  }

  const handleRoomPriceChange = (e, index) => {
    const { value, name } = e.target
    const selectedItem = data.rooms[index]
    // update selectedItem Name
    selectedItem[`${name}`] = value
    data.rooms[index] = selectedItem
    setData({ ...data, rooms: data.rooms })
  }

  const handleAddRoom = () => {
    const newRoomRow = {
      room_type: '',
      adult_price: '',
      child_price: ''
    }
    data.rooms.push(newRoomRow)
    setData({ ...data })
  }

  const handleRemoveRooms = (index) => {
    if (data?.rooms?.length > 1) {
      data?.rooms?.splice(index, 1)
      setData({ ...data })
    }
  }

  const handleServicePriceChange = (key, value) => {
    setData({ ...data, [key]: value });
  }

  function handlePriceTypeChange(e,) {
    const { value } = e.target
    setData({ ...data, price_type: value })

  }

  function handleTaxesTypeChange(e, index, taxType) {
    const { value } = e.target

    const selectedItem = data.taxes[index]

    if (value === 'percentage') {
      selectedItem[`value`] = ''
      selectedItem[`${taxType}`] = value
      data.taxes[index] = selectedItem
      setData({ ...data, taxes: data.taxes })
    } else {
      selectedItem[`value`] = ''
      selectedItem[`${taxType}`] = value
      data.taxes[index] = selectedItem
      setData({ ...data, taxes: data.taxes })
    }
  }

  const handleAddTaxes = () => {
    const newTaxRow = {
      name: '',
      type: '',
      value: '',
    }
    data.taxes.push(newTaxRow)
    setData({ ...data })
  }

  const handleRemoveTaxes = (index) => {
    data?.taxes?.splice(index, 1)
    setData({ ...data })
    if (data?.taxes.length === 0) {
      setShowTaxes(false)
    }
  }

  const handleTaxNameChange = (e, index) => {
    const { value, name } = e.target
    const selectedItem = data.taxes[index]
    // update selectedItem Name
    selectedItem[`${name}`] = value
    // replace the item with updated name
    data.taxes[index] = selectedItem
    setData({ ...data, taxes: data.taxes })
  }

  const handleTaxPriceChange = (e, index) => {
    const { value, name } = e.target
    const selectedItem = data.taxes[index]
    // update selectedItem Name
    selectedItem[`${name}`] = value
    data.taxes[index] = selectedItem
    setData({ ...data, taxes: data.taxes })
  }

  useEffect(() => {
    onChange(data)
  }, [data])

  // --------- JSX Code --------
  return (
    <div className='pricing-holder'>

      <div className='row px-2 mb-3'>

        <div className='col-md-1 col-2 d-flex  align-items-center' >
          <h2 className='font-weight-bold '>{Service_Builder?.Price}</h2>
        </div>

        <div className='col-md-3 col-12' >
          <label htmlFor={`price_per_night`} className='taxes-type d-flex align-items-center justify-content-center'>
            <input
              type="radio"
              id={`price_per_night`}
              name={`price_type`}
              value="price_per_night"
              checked={data?.price_type === 'price_per_night'}
              onChange={(e) => handlePriceTypeChange(e)}
            />
            <p className='taxes-type-value'>  {Service_Builder?.price_per_night}</p>
          </label>
        </div>

        <div className='col-md-3 col-12' >
          <label htmlFor={`price_per_package`} className='taxes-type d-flex align-items-center justify-content-center'>
            <input
              type="radio"
              id={`price_per_package`}
              name={`price_type`}
              value="price_per_package"
              checked={data?.price_type === 'price_per_package'}
              onChange={(e) => handlePriceTypeChange(e)}
            />
            <p className='taxes-type-value'>  {Service_Builder?.price_per_package}</p>
          </label>
        </div>

        <div className='col-md-2 col-12' >
          <div className="violation-cost-input">
            <TextField
              disabled={data?.price_type === 'price_per_night'}
              hasLabel={false}
              placeholder='3'
              name="nights"
              id="nights"
              value={data?.nights}
              type="number"
              onChange={(e) => handleServicePriceChange("nights", Number(e.target.value))}
            />
          </div>
          <div className="violation-cost-select border-0 h-100 d-flex justify-content-end align-items-center">
            <p className='taxes-currency'>{Service_Builder?.night}</p>
          </div>
        </div>

        <div className='col-md-2 col-12' >
          <SelectField
            hasLabel={false}
            id="serviceCurrency"
            name="serviceCurrency"
            value={data?.serviceCurrency?.currency_code || data?.serviceCurrency?.name}
            options={allCurrencies}
            onChange={(selectedCurrency) => handleServicePriceChange("serviceCurrency", selectedCurrency)}
          />
        </div>

        <div className='col-md-1 col-2 d-flex justify-content-end align-items-center' >
          <button type='button' className='add_btn' onClick={() => handleAddRoom()}>
            <AddIcon />
            {Service_Builder?.add_room}
          </button>
        </div>

      </div>

      {/* Hotel  Rooms  Holder*/}
      {data?.rooms?.length > 0 &&
        <div className=' price-inputs-holder mb-3 '>
          {data?.rooms?.map((room, index) =>
            <div className='row  mb-3 '>
              <div className="col-md-3 col-12  violation-cost-wrapper">
                <SelectField
                  hasLabel={false}
                  placeholder={Service_Builder?.room_type}
                  id="room_type"
                  name="room_type"
                  value={allLookupsBuilder?.room_types?.find(item => item?.id === room?.room_type)?.name}
                  options={allLookupsBuilder?.room_types}
                  onChange={(e) => handleRoomType(e, index, "room_type")}
                />
              </div>

              <div className="col-md-4 col-12  violation-cost-wrapper">
                <div className="violation-cost-input">
                  <TextField
                    hasLabel={false}
                    placeholder={Service_Builder?.adult_price}
                    name="adult_price"
                    id="adult_price"
                    value={room?.adult_price}
                    type="number"
                    onChange={(e) => handleRoomPriceChange(e, index)}
                  />
                </div>
                <div className="violation-cost-select h-100 d-flex justify-content-end align-items-center">
                  <p className='taxes-currency'>{data?.serviceCurrency?.currency_code}</p>
                </div>
              </div>

              <div className="col-md-4 col-12 violation-cost-wrapper">
                <div className="violation-cost-input">
                  <TextField
                    hasLabel={false}
                    placeholder={Service_Builder?.child_price}
                    name="child_price"
                    id="child_price"
                    value={room?.child_price}
                    type="number"
                    onChange={(e) => handleRoomPriceChange(e, index)}
                  />
                </div>
                <div className="violation-cost-select h-100 d-flex justify-content-end align-items-center">
                  <p className='taxes-currency'>{data?.serviceCurrency?.currency_code}</p>
                </div>
              </div>

              <div className='col ' >
                <span
                  className='d-flex justify-content-center pointer-event align-items-center h-100'
                  onClick={() => handleRemoveRooms(index)}
                >
                  <TrashIcon />
                </span>
              </div>

            </div>
          )}
        </div>
      }

      {/* Select Taxes  and add Button*/}
      <div className='d-flex mb-3 justify-content-between align-items-center'>

        <div className="d-flex align-items-center">
          <input
            type="Checkbox"
            name={"eheck1"}
            id={"exampleCheck1"}
            checked={showTaxes}
            onChange={() => setShowTaxes(!showTaxes)}
          />

          <label
            className="form-check-label font-bold  pointer text-caption mx-2 rememberme-label"
            htmlFor={"exampleCheck1"}
          >
            {Service_Builder?.taxes}
          </label>
        </div>

        {showTaxes &&
          <button type='button' className='add_btn' onClick={() => handleAddTaxes()}>
            <AddIcon />
            {Service_Builder?.add_taxes}
          </button>
        }
      </div>

      {/* Taxes Row */}
      {(showTaxes && data?.taxes.length > 0) &&
        <div className='taxes-holder '>
          {data?.taxes?.map((tax, index) => (
            <div key={index} className='row px-3 mb-3 '>

              {/* tax name input */}
              <div className='col-12 col-lg-3 '>
                <div className="violation-cost-input">
                  <TextField
                    hasLabel={false}
                    placeholder={Service_Builder?.tax_name}
                    name={"name"}
                    id="name"
                    value={data?.taxes[index]?.name}
                    type="text"
                    onChange={(e) => handleTaxNameChange(e, index)}
                  />
                </div>
              </div>

              {/* percentage radio input */}
              <div className='col-5 col-lg-2  '>
                <label htmlFor={`fixed_price_${index}`} className='taxes-type d-flex align-items-center justify-content-center'>
                  <input
                    type="radio"
                    id={`fixed_price_${index}`}
                    name={`type${index}`}
                    value="fixed"
                    checked={tax?.type === 'fixed'}
                    onChange={(e) => handleTaxesTypeChange(e, index, 'type')}
                  />
                  <p className='taxes-type-value'>  {Service_Builder?.fixed_price}</p>
                </label>
              </div>

              {/* fixed Price input */}
              <div className='col-5 col-lg-2' >
                <div className="violation-cost-wrapper">
                  <div className="violation-cost-input">
                    <TextField
                      hasLabel={false}
                      disabled={tax?.type !== 'fixed'}
                      placeholder={"1000"}
                      name="value"
                      id="taxes_price"
                      value={data?.taxes?.find((tax, taxIndex) => (tax?.type === 'fixed' && taxIndex === index))?.value}
                      type="number"
                      onChange={(e) => handleTaxPriceChange(e, index)}
                    />
                  </div>
                  <div className="violation-cost-select h-100 d-flex justify-content-end align-items-center">
                    <p className='taxes-currency'>{data?.serviceCurrency?.currency_code}</p>
                  </div>
                </div>
              </div>

              {/* percentage radio input */}
              <div className='col-5 col-lg-2 ' >
                <label htmlFor={`percentage_${index}`} className='taxes-type d-flex align-items-center justify-content-center'>
                  <input
                    type="radio"
                    id={`percentage_${index}`}
                    name={`type${index}`}
                    value={"percentage"}
                    checked={tax?.type === 'percentage'}
                    onChange={(e) => handleTaxesTypeChange(e, index, 'type')}
                  />
                  <p className='taxes-type-value'>  {Service_Builder?.percentage}</p>
                </label>
              </div>

              {/* percentage input */}
              <div className='col-5 col-lg-2 ' >
                <div className="violation-cost-wrapper">
                  <div className="violation-cost-input">
                    <TextField
                      hasLabel={false}
                      placeholder={'10 %'}
                      name="value"
                      disabled={tax?.type !== 'percentage'}
                      id="taxes_percentage"
                      value={data?.taxes?.find((tax, taxIndex) => ((tax?.type === 'percentage') && (taxIndex === index)))?.value}
                      type="number"
                      onChange={(e) => handleTaxPriceChange(e, index)}
                    />
                  </div>
                  <div className="violation-cost-select h-100 d-flex justify-content-end align-items-center">
                    <p className='taxes-currency'>{data?.serviceCurrency?.currency_code}</p>
                  </div>
                </div>
              </div>

              {/* delete btn */}
              <div className='col ' >
                <span
                  className='d-flex justify-content-center pointer-event align-items-center h-100'
                  onClick={() => handleRemoveTaxes(index)}
                >
                  <TrashIcon />
                </span>
              </div>

            </div>
          ))}
        </div>
      }

    </div>
  )
}

export default HotelPricesTaxes