import Loader from "components/Loader";
import Pagination from "components/Pagination";
import { useProductsBuilderDispatch } from "context/productsBuilder";
import ProductsTableRow from "modules/products-builder/components/ProductsTableRow";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import { getAllProducts } from "services/productbuilder";
import Locale from "../../../translations";
import ProductFilter from "../components/ProductFilter";
import ShowForPermission from "helpers/showForPermission";
import PlusIcon from '../Pricing/IconsSVG/PlusIcon';
import { useLocation, useParams } from 'react-router-dom';
// import ShowSubscription from 'helpers/ShowSubscription';

export default function Products(props) {
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	// const products = productBody.product;
	const search = props.location.search;
	const name = new URLSearchParams(search);
	const location = useLocation();
	const { bundle } = useParams();

	const { productsList, productsBuilder, inventory } = Locale;
	const [orderBy, setOrderBy] = useState(null);
	const [sortBy, setSortBy] = useState(null);
	const [products, setProducts] = useState([]);
	const [ProductsTab, setProductsTab] = useState([])
	const [isOpen, setisOpen] = useState("");
	const [isOpenPrintPdf, setisOpenPrintPdf] = useState("");
	const [isOpenPrintExcel, setisOpenPrintExcel] = useState("");
	const [isOpenPrintTravellers, setisOpenPrintTravellers] = useState("");
	const [isOpenPrintDropdown, setisOpenPrintDropdown] = useState("");


	const [isTap, setIsTap] = useState("all")
	const [meta, setMeta] = useState({});
	// ----------------------------------------------------------------------------------------------------
	// Component Functions
	// ----------------------------------------------------------------------------------------------------
	const setSorting = (order, sort) => {
		setOrderBy(order);
		setSortBy(sort);
	};

	async function fetchProducts(filters = null, page = 1) {
		const response = await getAllProducts({ ...filters, version: 2 }, page);
		if (response.status === 200) {
			setProducts(response.data.data);
			setMeta(response.data.meta);
		} else {
			store.addNotification({
				title: "Something Went Wrong!",
				message: response.data.message,
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}

	const goTo = (page) => {
		fetchProducts(null, page);
	};


	useEffect(() => {
		if (isTap === "Published") {
			setProductsTab(products.filter((res) => res.published_at !== null))
		} else {
			setProductsTab(products);
		}

	}, [isTap,products]);


	// ----------------------------------------------------------------------------------------------------
	// Mini Components UI
	// ----------------------------------------------------------------------------------------------------
	// Sort Arrow View
	const sortArrowView = (order) => {
		if (orderBy === order && sortBy === "asc") {
			return <i className="fas fa-sort-down px-1 pointer"></i>;
		} else if (orderBy === order && sortBy === "desc") {
			return <i className="fas fa-sort-up px-1 pointer"></i>;
		} else {
			return "";
		}
	};
	const tableRows =
		ProductsTab?.length > 0 ? (
			ProductsTab?.map((product, i) => (
				<ProductsTableRow
					key={i}
					fetchProducts={fetchProducts}
					product={product}
					isOpen={isOpen}

					setisOpen={setisOpen}
					isOpenPrintPdf={isOpenPrintPdf}

					setisOpenPrintPdf={setisOpenPrintPdf}
					isOpenPrintExcel={isOpenPrintExcel}

					setisOpenPrintExcel={setisOpenPrintExcel}
					isOpenPrintTravellers={isOpenPrintTravellers}
					setisOpenPrintTravellers={setisOpenPrintTravellers}
					isOpenPrintDropdown={isOpenPrintDropdown}
					setisOpenPrintDropdown={setisOpenPrintDropdown}
				/>
			))
		) : (
			<tr>
				<td colSpan="11">
					<div className="product-build__product-no-data">
						<i className="fas fa-info-circle fa-lg"></i>{" "}
						<h4>{productsBuilder.noResult}</h4>
					</div>
				</td>
			</tr>
		);

	const dispatch = useProductsBuilderDispatch();
	const history = useHistory();

	const editProducts = async () => {
		localStorage.setItem("uuid", undefined);
		dispatch({ type: "deleteProduct", payload: {} });
		dispatch({ type: "saveBasicDetails", payload: { isEditMode: false } });
		console.log(`/${bundle}/products-builder`,);
		
		history.push({
			pathname: `/${bundle}/products-builder/create`,
			search:location.search,
		});
	};

	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------
	if (true) {
		return (
			<>
				{/* <ShowSubscription module={"trip_builder"}> */}
					{/* Products Filter */}
					{/* <div className="mx-md-5 mb-5" style={{ width: "90%", margin: "auto" }}> */}
					{/* <p style={{ fontSize: "20px" }} className="text-gray mb-3">
						{productsBuilder.tripManagement}
					</p> */}
					<div className="col-md-12 bg-white py-3 px-4 mb-3 rounded-lg">
						<div className="header-Filter mb-3">

								<h1 className="title-Filter">{productsBuilder.tripManagement}</h1>
							
							<div>
								<ShowForPermission permission="Manage-Trip-Management-Module">
									<button
										className="btn-Product-Filter bg-nxt"
										onClick={editProducts}
									>
										<PlusIcon />
										{productsBuilder.addTrips}
									</button>
								</ShowForPermission>
							</div>
						</div>

						<ProductFilter
							orderBy={orderBy}
							sortBy={sortBy}
							currentPage={meta.current_page}
							fetchProducts={fetchProducts}
						/>
						<div className="rounded overfow-hidden border mb-4">
							<div className="filter-taps-box">
								<p
									className={`tap-list ${isTap === "all" ? "active" : ""}`}
									role="button"
									onClick={() => {
										setIsTap("all");
									}}
								>
									{productsBuilder.all} <span className="ele-round">{products.length}</span>{" "}
								</p>
								<p
									className={`tap-list ${
										isTap === "Published" ? "active" : ""
										}`}
									role="button"
									onClick={() => {
										setIsTap("Published");
									}}
								>
									{productsBuilder.Published}
									<span className="ele-round">
										{products.filter((res) => res.published_at !== null).length}
									</span>{" "}
								</p>
							</div>
							<div className="table-responsive  p-0">
								<table striped className="table mb-0 bg-white">
									<thead className="th-trip-mang ">
										<tr>
											<th className="border-top-0">{productsList.id}</th>
											<th>{productsList.productName}</th>
											<th>{productsList.createdAt}</th>
											<th>{productsList.productClass}</th>
											<ShowForPermission permission="Manage-Trip-Management-Module">
												<th>{productsList.pricing}</th>
											</ShowForPermission>
											<th style={{ maxWidth: "300px" }}>
												{productsList.products}
											</th>

											<th
											// onClick={() =>
											// 	setSorting(
											// 		"validation_date_from_at",
											// 		sortBy === "asc" ? "desc" : "asc"
											// 	)
											// }
											>
												{/* <span class="up-down">
											<i class="fas fa-sort"></i>
										</span>
										{sortArrowView("validation_date_from_at")}{" "} */}
												{/* <span class="up-down">
											<i class="fas fa-sort"></i>
										</span>	 */}
												{productsList.validation}
											</th>
											<th>
												{/* <span class="up-down">
											<i class="fas fa-sort"></i>
										</span> */}
												{sortArrowView("departure_date_at")}{" "}
												{productsList.tripDate}
											</th>

											<th>{productsList.status}</th>

											<ShowForPermission permission="Manage-Trip-Management-Module">
												<th>{inventory.messages.actions}</th>
											</ShowForPermission>
										</tr>
									</thead>
									<tbody>{tableRows}</tbody>
								</table>
							</div>
						</div>
					{/* Pagination */}
					</div>
					{products.length > 0 ? (
						<Pagination info={meta} goTo={goTo} />
					) : null}
					{/* </div> */}
				{/* </ShowSubscription> */}
			</>
		);
	} else {
		return <Loader />;
	}
}
