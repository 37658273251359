import TextField from "components/Form/TextField/TextField";
import { useSBSState } from 'context/global';
import { useMarketplaceState } from 'context/marketplace';
import { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import Locale from "translations";
import filterReset from "../../../../assets/images/filterReset.svg";

function HotelFilter({
	searchFilters,
	setAllFilters,
	allFilters,
	maxPrice,
	minPrice,
	setPrice,
	price,
	lookups
}) {
	const { filters } = useMarketplaceState();
	const { marketPlace, payment } = Locale;
	const [hotelFilters, setHotelFilters] = useState(null);
	const [isOpenstarRate, setIsOpenstarRate] = useState(true);
	const [isOpenPropretyType, setIsOpenPropretyType] = useState(true);
	const [isOpenPriceRange, setIsOpenPriceRange] = useState(true);
	const [isOpenHotelFacilities, setIsOpenHotelFacilities] = useState(true);
	const [isOpenMealOptions, setIsOpenMealOptions] = useState(true);
	const [isOpenSearch, setIsOpenSearch] = useState(true);
	const [isOpenCancellationPolicy, setIsOpenCancellationPolicy] = useState(true);

	const [accommodationTerm, setAccommodationTerm] = useState("");
	const [filterdAccommodations, setFilterdAccommodations] = useState();
	const [borderBaseTerm, setBorderBaseTerm] = useState("");
	const [filterdBorderBases, setFilterdBorderBases] = useState();

	// const [isOpenPointsOfInterset, setIsOpenPointsOfInterset] = useState(true);

	const [selectedRating, setSelectedRating] = useState([]);
	const [selectedFacilities, setSelectedFacilities] = useState([]);
	const [selectedMealsOptions, setSelectedMealsOptions] = useState([]);
	const [selectedPropertyTypes, setSelectedPropretyTypes] = useState([]);
	const [isRefundable, setIsRefundable] = useState(null);

	// filter by price range
	const filterByPrice = (type, value) => {
		if (price.min < 0) price.min = 0;
		if (price.max > maxPrice) price.max = +maxPrice;
		const search_filters = {
			search_filters: { ...hotelFilters, price },
		};
		searchFilters(search_filters);
		setAllFilters(search_filters);
		setHotelFilters({ ...hotelFilters, price });
	};
	// filter by any checkbox (rating, meals , facilties, proprety_type)
	function filterByCheckbox(e, value, state, stateSetter) {
		const checked = e.target.checked;
		const filterName = e.target.name;
		let data;
		if (checked) {
			stateSetter([...state, value]);
			data = {
				[filterName]: [...state, value],
			};
		} else {
			stateSetter(state?.filter((item) => item !== value));
			data = {
				[filterName]: state?.filter((item) => item !== value),
			};
		}
		const search_filters = { search_filters: { ...hotelFilters, ...data } };
		searchFilters(search_filters);
		setAllFilters(search_filters);
		setHotelFilters({ ...hotelFilters, [filterName]: data?.[filterName] });
	}

	function filterByRefundPolicy(e) {
		const checked = e.target.checked;
		let data;
		if (checked) {
			setIsRefundable(1);
			data = {isRefundable: 1};
		} else {
			setIsRefundable(null);
			data = {isRefundable: null};
		}
		const search_filters = { search_filters: { ...hotelFilters, ...data } };
		searchFilters(search_filters);
		setAllFilters(search_filters);
		setHotelFilters({ ...hotelFilters, isRefundable: data.isRefundable });
	}

	// search in lookups names (accommodations, border bases)
	function searchByTextInLookups(e, originalArray, textStateSetter, filterdArraySetter) {
		const value = e.target.value;
		if (e.target.value !== "") {
			const filter = originalArray?.filter((item) => {
				return (
					item?.name.toLowerCase().includes(value.toLowerCase()) && item
				);
			});
			filterdArraySetter(filter);
		} else {
			filterdArraySetter(originalArray);
		}
		textStateSetter(value);
	}


	const reset = () => {
		setHotelFilters(null);
		setPrice({ min: minPrice, max: maxPrice });
		setAllFilters({});
		setSelectedFacilities([]);
		setSelectedRating([]);
		setSelectedMealsOptions([]);
		setSelectedPropretyTypes([]);
		setIsRefundable(null);
		searchFilters({});
		setAccommodationTerm("");
		setFilterdAccommodations(lookups?.accommodations);
		setBorderBaseTerm("");
		setFilterdBorderBases(lookups?.borderBases);
	};


	// set price range when min or max price changes
	useEffect(() => {
		setPrice({ min: minPrice, max: maxPrice });
	}, [minPrice, maxPrice]);


	useEffect(() => {
		if (!price && minPrice && maxPrice) {
			setPrice({ min: minPrice, max: maxPrice });
		}
	}, [price, minPrice, maxPrice]);


	useEffect(() => {
		setFilterdAccommodations(lookups?.accommodations);
		setFilterdBorderBases(lookups?.borderBases);
	}, [lookups?.accommodations, lookups?.borderBases]);

	
	return (
		<>
			<div className="d-flex justify-content-between align-items-center mt-2">
				<div className="filter-head terms-color text-uppercase ">
					<h6 className="font-weight-bold h6 hotel-found">
						{marketPlace.messages.filterBy}:
					</h6>
				</div>
				<button
					className="btn bg-white clear-filter px-3 mx-2"
					onClick={() => reset()}
				>
					<img src={filterReset} alt="" className="mx-2" />
					{payment.messages.clear}
				</button>
			</div>
			<div className="">
				{/* <!--fliter-title--> */}
				<div className="bg-white p-3 rounded mt-2">
					<h6
						className="font-weight-bold  terms-color"
						onClick={() => setIsOpenSearch(!isOpenSearch)}
					>
						{marketPlace.messages.search}
						<span className="float-right">
							<i className="fa fa-angle-down"></i>
						</span>
					</h6>
					<Collapse isOpen={isOpenSearch}>
						<div className="col-md-12 mt-3">
							<TextField
								type="text"
								hasLabel={false}
								placeholder={marketPlace.messages.search}
								value={hotelFilters?.name ? hotelFilters?.name : ""}
								onChange={(e) => {
									const search_filters = {
										search_filters: { ...hotelFilters, name: e.target.value },
									};
									searchFilters(search_filters);
									setHotelFilters(search_filters.search_filters);
									setAllFilters(search_filters);
								}}
							/>{" "}
						</div>
					</Collapse>
				</div>
				{/* price range */}
				<div className="bg-white p-3 rounded mt-2">
					<h6
						className="font-weight-bold  terms-color"
						onClick={() => setIsOpenPriceRange(!isOpenPriceRange)}
					>
						{marketPlace.messages.priceRange}
						<span className="float-right">
							<i className="fa fa-angle-down"></i>
						</span>
					</h6>
					<Collapse isOpen={isOpenPriceRange}>
						<div className="d-flex  align-items-center mt-3 filter-price-range">
							<div>
								<TextField
									hasLabel={false}
									type="number"
									min={1}
									size="small"
									sx={{ width: "80px" }}
									value={
										price?.min?.toString()[0] === "0"
											? price?.min?.toString().slice(1)
											: price?.min
									}
									onChange={(e) => {
										setPrice({
											...price,
											min:
												e.target.value.charAt(0) === "0"
													? +e.target.value.slice(1)
													: +e.target.value,
										});
										filterByPrice(
											"min",
											e.target.value.charAt(0) === "0"
												? +e.target.value.slice(1)
												: +e.target.value
										);
									}}
									autoComplete="off"
									extraTextPosition={"append"}
									extraText={filters?.currency_code?.label}
								/>
							</div>
							<span className="mx-2 text-uppercase">{marketPlace.to}</span>
							<div>
								<TextField
									hasLabel={false}
									type="number"
									size="small"
									min={1}
									sx={{ width: "80px" }}
									value={price?.max?.toString()[0] === "0"
										? price?.max?.toString().slice(1)
										: price?.max}

									onChange={(e) => {
										setPrice({
											...price, max: e.target.value.charAt(0) === "0"
												? +e.target.value.slice(1)
												: +e.target.value,
										});
										filterByPrice("max", e.target.value.charAt(0) === "0"
											? +e.target.value.slice(1)
											: +e.target.value);
									}}
									autoComplete="off"
									extraTextPosition={"append"}
									extraText={filters?.currency_code?.label}
								/>
							</div>
						</div>
					</Collapse>
				</div>
				{/* proprety type */}
				<div className="airlines bg-white p-3 rounded mt-2 h-50">
					<h6
						className="font-weight-bold terms-color" role="button"
						onClick={() => setIsOpenPropretyType(!isOpenPropretyType)}
					>
						{marketPlace.propertyType}

						<span className="float-right">
							<i className="fa fa-angle-down"></i>
						</span>
					</h6>
					<Collapse isOpen={isOpenPropretyType}>
						<div className='mt-3'>
							<TextField
								type="text"
								hasLabel={false}
								placeholder={marketPlace.messages.search}
								value={accommodationTerm}
								onChange={(e) => searchByTextInLookups(e, lookups?.accommodations, setAccommodationTerm, setFilterdAccommodations)}
							/>
						</div>

						<ul className="list-unstyled mt-3 list-with-height">
							{filterdAccommodations?.map((accommodation, idx) => {
								return (
									<li key={`accommodation-${accommodation?.id}-${idx}`}>
										<div className="airlines-checkbox">
											<div className="custom-control custom-checkbox list-with-height">
												<input
													key={`accommodation-${accommodation?.id}-${idx}`}
													className="custom-control-input permChecks"
													id={accommodation?.id}
													name="accommodations"
													type="checkbox"
													value={accommodation?.id}
													onChange={(e) => {
														filterByCheckbox(e, +e.target.value, selectedPropertyTypes, setSelectedPropretyTypes)
													}}
													checked={selectedPropertyTypes.includes(accommodation?.id) ? true : false}
												/>
												<label
													className="custom-control-label my-1 text-capitalize"
													htmlFor={accommodation?.id}
												>
													{accommodation?.name}
												</label>
											</div>
										</div>
									</li>
								);
							})}
						</ul>


					</Collapse>
				</div>
				{/* star rate */}
				<div className="airlines bg-white p-3 rounded mt-2">
					<h6
						className="font-weight-bold terms-color" role="button"
						onClick={() => setIsOpenstarRate(!isOpenstarRate)}
					>
						{marketPlace.messages.starRate}

						<span className="float-right">
							<i className="fa fa-angle-down"></i>
						</span>
					</h6>
					<Collapse isOpen={isOpenstarRate}>
						<ul className="list-unstyled mt-3">
							{[5, 4, 3, 2, 1].map((el) => {
								return (
									<li key={el}>
										<div className="airlines-checkbox">
											<div className="custom-control custom-checkbox ">
												<input
													className="custom-control-input permChecks"
													id={`classification-0${el}`}
													name="rating"
													type="checkbox"
													value={el}
													onChange={(e) => filterByCheckbox(e, +e.target.value, selectedRating, setSelectedRating)}
													checked={selectedRating.includes(el) ? true : false}
												/>
												<label
													className="custom-control-label"
													htmlFor={`classification-0${el}`}
												>
													{[1, 2, 3, 4, 5].map((_, i) => {
														return el <= i ? (
															<i key={i} className="far fa-star"></i>
														) : (
															<i key={i} className="fas fa-star"></i>
														);
													})}
												</label>
											</div>
										</div>
									</li>
								);
							})}
						</ul>
					</Collapse>
				</div>

				{/* meals options */}
				<div className="airlines bg-white p-3 rounded mt-2">
					<h6
						className="font-weight-bold terms-color"
						onClick={() => setIsOpenMealOptions(!isOpenMealOptions)}
					>
						{marketPlace.messages.mealOptions}
						<span className="float-right">
							<i className="fa fa-angle-down"></i>
						</span>
					</h6>
					<Collapse isOpen={isOpenMealOptions}>
						<div className='mt-3'>
							<TextField
								type="text"
								hasLabel={false}
								placeholder={marketPlace.messages.search}
								value={borderBaseTerm}
								onChange={(e) => searchByTextInLookups(e, lookups?.borderBases, setBorderBaseTerm, setFilterdBorderBases)}
							/>
						</div>
						<ul className="list-unstyled mt-3 list-with-height">
							{filterdBorderBases?.map((meal, idx) => {
								return (
									<li key={`meal-${meal?.id}-${idx}`}>
										<div className="airlines-checkbox">
											<div className="custom-control custom-checkbox ">
												<input
													className="custom-control-input permChecks"
													id={`meal-${meal?.id}-${idx}`}
													name="borderbases"
													type="checkbox"
													value={meal?.id}
													onChange={(e) => filterByCheckbox(e, +e.target.value, selectedMealsOptions, setSelectedMealsOptions)}
													checked={
														selectedMealsOptions.includes(meal.id) ? true : false
													}
												/>
												<label
													className="custom-control-label my-1 text-capitalize"
													htmlFor={`meal-${meal?.id}-${idx}`}
												>
													{meal?.name?.toLowerCase()}
												</label>
											</div>
										</div>
									</li>
								);
							})}
						</ul>
					</Collapse>
				</div>

				{/* Cancellation Policy */}
				<div className="airlines bg-white p-3 rounded mt-2">
					<h6
						className="font-weight-bold terms-color "
						onClick={() => setIsOpenCancellationPolicy(!isOpenCancellationPolicy)}
					>
						{marketPlace.messages.cancellationPolicy}
						<span className="float-right">
							<i className="fa fa-angle-down"></i>
						</span>
					</h6>
					<Collapse isOpen={isOpenCancellationPolicy}>
						<ul className="list-unstyled mt-3">
							<li>
								<div className="airlines-checkbox">
									<div className="custom-control custom-checkbox ">
										<input
											className="custom-control-input permChecks"
											id={`isRefundable`}
											name="isRefundable"
											type="checkbox"
											value={isRefundable}
											onChange={(e) => filterByRefundPolicy(e)}
											checked={isRefundable ? true : false}
										/>
										<label
											className="custom-control-label"
											htmlFor={`isRefundable`}
										>
											{marketPlace.messages.isRefundable}
										</label>
									</div>
								</div>
							</li>

						</ul>

					</Collapse>
				</div>

				{/* Hotel Facilities  */}
				<div className="airlines bg-white p-3 rounded mt-2">
					<h6
						className="font-weight-bold terms-color"
						onClick={() => setIsOpenHotelFacilities(!isOpenHotelFacilities)}
					>
						{marketPlace.messages.hotelFacilities}
						<span className="float-right">
							<i className="fa fa-angle-down"></i>
						</span>
					</h6>
					<Collapse isOpen={isOpenHotelFacilities}>

						<ul className="list-unstyled my-3">
							{["internet", "restaurant", "gym", "spa"].map((facility) => {
								return (
									<li key={facility}>
										<div className="airlines-checkbox">
											<div className="custom-control custom-checkbox ">
												<input
													className="custom-control-input permChecks"
													id={facility}
													name="facilities"
													type="checkbox"
													value={facility}
													onChange={(e) => filterByCheckbox(e, e.target.value, selectedFacilities, setSelectedFacilities)}
													checked={selectedFacilities.includes(facility) ? true : false}
												/>
												<label
													className="custom-control-label"
													htmlFor={facility}
												>
													{marketPlace.messages[facility]}
												</label>
											</div>
										</div>
									</li>
								);
							})}
						</ul>
						{/* <span className='filter-showall'>{marketPlace.showAll}</span> */}
					</Collapse>
				</div>

				{/* point of interset  */}
				{/* <div className="airlines bg-white p-3 rounded mt-2">
					<h6
						className="font-weight-bold terms-color"
						onClick={() => setIsOpenPointsOfInterset(!isOpenPointsOfInterset)}
					>
						{marketPlace.pointsOfInterest}
						<span className="float-right">
							<i className="fa fa-angle-down"></i>
						</span>
					</h6>
					<Collapse isOpen={isOpenPointsOfInterset}>
						<div className='mt-3'>
							<TextField
								type="text"
								hasLabel={false}
								placeholder={marketPlace.messages.search}
							// value={""}
							// onChange={(e) => {}}
							/>
						</div>
						<ul className="list-unstyled my-3">
							{["Dubai Dolphinarium", "Al Fahidi", "Dubai Mall", "Dubai Marina"].map((pointsOfInterest) => {
								return (
									<li key={pointsOfInterest}>
										<div className="airlines-checkbox">
											<div className="custom-control custom-checkbox ">
												<input
													className="custom-control-input permChecks"
													id={pointsOfInterest}
													name="classification[]"
													type="checkbox"
													value={pointsOfInterest}
												// onChange={filterByFacilities}
												// checked={facilities.includes(pointsOfInterest) ? true : false}
												/>
												<label
													className="custom-control-label"
													htmlFor={pointsOfInterest}
												>
													{pointsOfInterest}
												</label>
											</div>
										</div>
									</li>
								);
							})}
						</ul>
						<span className='filter-showall'>{marketPlace.showAll}</span>
					</Collapse>
				</div> */}

				{/* <div className="airlines">
				<h6 className="font-weight-bold">
					{marketPlace.messages.distanceFromHaram}
				</h6>
				<ul className="list-unstyled">
					{[1000, 2000, 3000].map((dist) => {
						return (
							<li key={`d-${dist}`}>
								<div className="airlines-checkbox">
									<div className="custom-control custom-checkbox ">
										<input
											className="custom-control-input permChecks"
											id={dist}
											name="classification[]"
											type="checkbox"
											value={dist}
											onChange={filterByDistance}
											checked={distance.includes(dist) ? true : false}
										/>
										<label className="custom-control-label" htmlFor={dist}>
											{marketPlace.messages.lessThan} {dist.toString()[0]}
											{marketPlace.messages.km}
										</label>
									</div>
								</div>
							</li>
						);
					})}
				</ul>
			</div> */}

				{/* <div className="airlines">
				<h6 className="font-weight-bold">{marketPlace.messages.roomOffers}</h6>
				<ul className="list-unstyled">
					{["full board", "half board", "room only"].map((meal) => {
						return (
							<li key={meal.replace(" ", "-")}>
								<div className="airlines-checkbox">
									<div className="custom-control custom-checkbox ">
										<input
											className="custom-control-input permChecks"
											id={meal.replace(" ", "-")}
											name="classification[]"
											type="checkbox"
											value={meal}
											onChange={filterByBoarding}
											checked={boarding.includes(meal) ? true : false}
										/>
										<label
											className="custom-control-label"
											htmlFor={meal.replace(" ", "-")}
										>
											{
												marketPlace.messages[
													meal.split(" ")[0] +
														meal.split(" ")[1].charAt(0).toUpperCase() +
														meal.split(" ")[1].slice(1)
												]
											}
										</label>
									</div>
								</div>
							</li>
						);
					})}
				</ul>
			</div> */}

				{/* <div className="airlines">
				<h6 className="font-weight-bold">
					{marketPlace.messages.cancellationPolicy}
				</h6>
				<ul className="list-unstyled">
					{["has_free_cancellation"].map((option, i) => {
						return (
							<li key={option}>
								<div className="airlines-checkbox">
									<div className="custom-control custom-checkbox ">
										<input
											className="custom-control-input permChecks"
											id={option}
											name="classification[]"
											type="checkbox"
											value={option}
											onChange={filterByPayment}
											checked={options.includes(option) ? true : false}
										/>
										<label className="custom-control-label" htmlFor={option}>
											{marketPlace.messages.freeCancellation}
										</label>
									</div>
								</div>
							</li>
						);
					})}
				</ul>
			</div> */}
			</div>
		</>
	);
}

export default HotelFilter;
