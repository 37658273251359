import { useEffect, useState } from 'react';
// Translations
import Locale from 'translations';
// Components
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField'
// Assets
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png"
import { ReactComponent as ResetIcon } from "assets/images/serviceBuilder/clear.svg";
// ----------------------------------------------------------------------------------------------------

const tags = [
  { label: 'All', id: null },
  { label: 'Hotel', id: 'builder_hotel' },
  { label: 'Flights', id: 'builder_flight' },
  { label: 'Transfer', id: 'builder_transfer' },
  { label: 'Attractions', id: 'builder_attraction' },
  { label: 'Tours', id: 'builder_tour' },
  { label: 'Package', id: 'builder_package' },
  { label: 'Others', id: 'builder_other' },
]



const ServiceBuilderSearchFilter = ({ onSearch }) => {

  const { Service_Builder } = Locale;

  const statusList = [
    { name: Service_Builder.disabled, id: 'disabled' },
    { name: Service_Builder.enabled, id: 'enabled' }
  ]

  const [searchFilter, setSearchFilter] = useState({
    productName: '',
    status: '',
    tag: tags[0]?.id
  })
  const handleSearchFilter = (e) => {
    if (e?.target?.value?.length >= 3) {
      setSearchFilter((prevFilter) => ({ ...prevFilter, productName: e.target.value }))
    }
  }
  const handleClearFilter = () => {
    setSearchFilter({
      productName: '',
      status: '',
      tag: ''
    })
  }

  useEffect(() => {
    onSearch(searchFilter)
  }, [searchFilter])

  // --------- JSX Code ---------
  return (
    <div
      className='row py-3'
      style={{
        borderTop: '1px solid #f7f6f6',
        borderBottom: '1px solid #f7f6f6',
        margin: '0px 5px'
      }}
    >

      {/* Inputs Holder  */}
      <div className='col-12 col-md-6 col-lg-5 col-xl-4 '>

        <div className='filter-inputs-holder'>

          <div style={{ width: '340px' }}>
            <TextField
              label={Service_Builder?.search}
              type="text"
              placeholder={Service_Builder?.type_product_name}
              value={searchFilter.productName}
              isImage={true}
              image={SeachIcon}
              prependImage={true}
              // icon={<SearchIcon className='position-absolute' style={{ top: '50%', right: '0px', transform: 'translateY(-50%)' }} />}
              onChange={(e) => handleSearchFilter(e)}
            />
          </div>

          <div style={{ width: '150px' }}>
            <SelectField
              label={Service_Builder?.status}
              placeholder='All'
              options={statusList}
              value={searchFilter?.status?.name}
              onChange={(e) => { setSearchFilter((prevFilter) => ({ ...prevFilter, status: e })) }}
            />
          </div>

          <button className="clear-btn" onClick={handleClearFilter} >
            <ResetIcon />
            {Service_Builder?.clear}
          </button>

          <div style={{ background: '#F7F6F6', width: '1px', height: '50px', alignSelf: 'end' }} />

        </div>

      </div>

      {/* Tags Holder  */}
      <div className='col-12 col-md-6 col-lg-7  col-xl-8'>

        <div className='filter-tags-holder'>
          {tags?.map(tag =>
            <span
              key={tag?.id}
              className={`${searchFilter?.tag === tag?.id ? 'active-tag' : 'tag'} `}
              onClick={() =>
                setSearchFilter((prevFilter) => ({ ...prevFilter, tag: tag?.id }))
              }
            >
              {tag?.label}
            </span>
          )}
        </div>

      </div>

    </div>
  )
}

export default ServiceBuilderSearchFilter

