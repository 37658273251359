export const Travellers = `<!DOCTYPE html>
<html lang="ar">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Travellers</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            direction: rtl;
            margin: 0;
            padding: 10px;
            font-size: 12px;
        }

        h1 {
            font-size: 16px;
            text-align: center;
            margin-bottom: 5px;
        }

        p {
            text-align: center;
            margin-top: 5px;
            font-size: 20px;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 10px;
        }

        td, th {
            border: 1px solid black;
            padding: 4px;
            text-align: center;
        }

        th {
            background-color: #e0e0e0;
        }


        .section-title {
            font-weight: bold;
            text-align: center;
            padding: 4px;
              font-size: 24px;
        }

        .footer {
            text-align: center;
            margin-top: 10px;
        }
    </style>
</head>
<body>

      <p>للحج و العمرة كشف ركاب رحلة رقم [booking_number] بتاريخ [arrival_date] </p>

    <div class="section-title">بيانات المسافرين</div>
    <table>
        <tr>
                    <th>الاسم</th>
            <th>الجنسية</th>

            <th>رقم الجواز</th>
               <th>اصدار</th>
           <th>انتهاء</th>
               <th>مصدره</th>

            <th>الجنس</th>

              <th>المرافقون</th>

            <th>اسم المحرم</th>
        </tr>
     [reservations]
    </table>

    <div class="footer">
    <p>
        POWERED BY: 
        <a href="https://safavisa.com" style="color: blue; text-decoration: none;">
            SAFAVISA.COM
        </a>
    </p>

    <img
        src="[SafaLogo]"
        alt="safa_logo"
        style="width: 187.93px; height: 42.45px"
    />
</div>
</body>
</html>`








export const BridgeInspection = `<!DOCTYPE html>
<html lang="ar" dir="rtl">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Travellers</title>
    <style>
       

        table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 10px;
        }

        td, th {
            border: 1px solid black;
            padding: 4px;
            text-align: center;
        }

        th {
            background-color: #e0e0e0;
        }


        .section-title {
            font-weight: bold;
            text-align: center;
            padding: 4px;
              font-size: 24px;
        }

        .footer {
            text-align: center;
            margin-top: 10px;
        }
             

         body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 20px;
        }
        .container {
          
            padding: 20px;
           
            max-width: 800px;
            margin: 0 auto;
        }
        .header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 20px;
        }
        .right-side, .center, .left-side {
            flex: 1;
        }
        .center {
            text-align: center;
        }
        .left-side {
            text-align: right;
        }
        h1 {
            font-size: 18px;
            margin: 0 0 10px 0;
        }
        p {
            margin: 5px 0;
            font-size: 14px;
        }
        .form-field {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
        }
        .form-field span:first-child {
            font-weight: bold;
        }
        .form-field span:last-child {
            
            flex: 1;
            margin-right: 10px;
        }

         .attendance-header {
            font-family: Arial, sans-serif;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            max-width: 600px;
            margin: 20px auto;
        }
        .attendance-item {
            display: flex;
            align-items: center;
        }
        .attendance-label {
            font-weight: bold;
            margin-left: 5px;
        }
        .attendance-value {
            border: 1px solid #000;
            padding: 2px 5px;
            min-width: 20px;
            text-align: center;
        }

    </style>
</head>
<body>
 <div class="container">
        <div class="header">
            <div class="right-side">
                

                  <h1>المملكة العربية السعودية</h1>
                <h1>وزارة الداخلية</h1>
                <h1>المديرية العامة للجوازات</h1>
            </div>
            <div class="center">
                <h1>بسم الله الرحمن الرحيم</h1>
                <h1>رقم البيان</h1>
                <h1>( 1 )</p>
                <h1>وسيلة السفر : Air</h1>
            </div>
            <div class="left-side">
               <h1>رقم السائق :</h1>
                <h1>رقم السيارة و نوعها :</h1>
                <h1>جنسية السيارة :</h1>
            </div>
        </div>
        <div class="form-fields">
            <div class="form-field">
                <h1>اسم المرخص له :</h1>
                <span></span>
            </div>
            <div class="form-field">
                <h1>اسم الوكيل :</h1>
                <span></span>
            </div>
            <div class="form-field">
                <h1>رقم الترخيص :</h1>
                <span></span>
            </div>
        </div>
    </div>


    <div class="section-title">بيانات المسافرين</div>
    <table>
        <tr>
                    <th>الاسم</th>
            <th>الجنسية</th>

            <th>رقم الجواز</th>
            <th>تاريخ الميلاد</th>
            <th>كرت جسر</th>

            <th>الجنس</th>

              <th>المرافقون</th>

            <th>اسم المحرم</th>
                        <th>الترميز</th>

        </tr>
     [reservations]
    </table>





      <div class="attendance-header">
        <div class="attendance-item">
            <span class="attendance-label">مجموع المعتمرين :</span>
            <span class="attendance-value">---</span>
        </div>
        <div class="attendance-item">
            <span class="attendance-label">عدد الذكور :</span>
            <span class="attendance-value">---</span>
        </div>
        <div class="attendance-item">
            <span class="attendance-label">عدد الإناث :</span>
            <span class="attendance-value">---</span>
        </div>
        <div class="attendance-item">
            <span class="attendance-label">عدد المرافقين :</span>
            <span class="attendance-value">---</span>
        </div>
        <div class="attendance-item">
            <span class="attendance-label">الختم و التوقيع</span>
        </div>
    </div>

    <div class="footer">
    <p>
        POWERED BY: 
        <a href="https://safavisa.com" style="color: blue; text-decoration: none;">
            SAFAVISA.COM
        </a>
    </p>

   
</div>
</body>
</html>`








export const Borders = `<!DOCTYPE html>
<html lang="ar" dir="rtl">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Travellers</title>
    <style>
       

        table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 10px;
        }

        td, th {
            border: 1px solid black;
            padding: 4px;
            text-align: center;
        }

        th {
            background-color: #e0e0e0;
        }


        .section-title {
            font-weight: bold;
            text-align: center;
            padding: 4px;
              font-size: 24px;
        }

        .footer {
            text-align: center;
            margin-top: 10px;
        }
             

         body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 20px;
        }
        .container {
          
            padding: 20px;
           
            max-width: 800px;
            margin: 0 auto;
        }
        .header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 20px;
        }
        .right-side, .center, .left-side {
            flex: 1;
        }
        .center {
            text-align: center;
        }
        .left-side {
            text-align: right;
        }
        h1 {
            font-size: 18px;
            margin: 0 0 10px 0;
        }
        p {
            margin: 5px 0;
            font-size: 14px;
        }
        .form-field {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
        }
        .form-field span:first-child {
            font-weight: bold;
        }
        .form-field span:last-child {
            
            flex: 1;
            margin-right: 10px;
        }

         .attendance-header {
            font-family: Arial, sans-serif;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
           
            max-width: 600px;
            margin: 20px auto;
        }
        .attendance-item {
            display: flex;
            align-items: center;
        }
        .attendance-label {
            font-weight: bold;
            margin-left: 5px;
        }
        .attendance-value {
            border: 1px solid #000;
            padding: 2px 5px;
            min-width: 20px;
            text-align: center;
        }

    </style>
</head>
<body>
 <div class="container">
        <div class="header">
            <div class="right-side">
                

                  <h1>المملكة العربية السعودية</h1>
                <h1>وزارة الداخلية</h1>
                <h1>المديرية العامة للجوازات</h1>
            </div>
            <div class="center">
                <h1>بسم الله الرحمن الرحيم</h1>
                <h1>رقم البيان</h1>
                <h1>( 1 )</p>
                <h1>وسيلة السفر : Air</h1>
            </div>
            <div class="left-side">
               <h1>رقم السائق :</h1>
                <h1>رقم السيارة و نوعها :</h1>
                <h1>جنسية السيارة :</h1>
            </div>
        </div>
        <div class="form-fields">
            <div class="form-field">
                <h1>اسم المرخص له :</h1>
                <span></span>
            </div>
            <div class="form-field">
                <h1>اسم الوكيل :</h1>
                <span></span>
            </div>
            <div class="form-field">
                <h1>رقم الترخيص :</h1>
                <span></span>
            </div>
        </div>
    </div>


    <div class="section-title">بيانات المسافرين</div>
    <table>
        <tr>
                    <th>الاسم</th>
            <th>الجنسية</th>

            <th>رقم الجواز</th>
               <th>اصدار</th>
           <th>انتهاء</th>
             <th>الجنس</th>

              <th>المرافقون</th>

            <th>اسم المحرم</th>

        </tr>
     [reservations]
    </table>





      <div class="attendance-header">
        <div class="attendance-item">
            <span class="attendance-label">مجموع المعتمرين :</span>
            <span class="attendance-value">---</span>
        </div>
        <div class="attendance-item">
            <span class="attendance-label">عدد الذكور :</span>
            <span class="attendance-value">---</span>
        </div>
        <div class="attendance-item">
            <span class="attendance-label">عدد الإناث :</span>
            <span class="attendance-value">---</span>
        </div>
        <div class="attendance-item">
            <span class="attendance-label">عدد المرافقين :</span>
            <span class="attendance-value">---</span>
        </div>
        <div class="attendance-item">
            <span class="attendance-label">الختم و التوقيع</span>
        </div>
    </div>

    <div class="footer">
    <p>
        POWERED BY: 
        <a href="https://safavisa.com" style="color: blue; text-decoration: none;">
            SAFAVISA.COM
        </a>
    </p>

   
</div>
</body>
</html>`







export const Palestine = `<!DOCTYPE html>
<html lang="ar" dir="rtl">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Travellers</title>
    <style>
       

        table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 10px;
        }

        td, th {
            border: 1px solid black;
            padding: 4px;
            text-align: center;
        }

        th {
            background-color: #e0e0e0;
        }


        .section-title {
            font-weight: bold;
            text-align: center;
            padding: 4px;
              font-size: 24px;
        }

        .footer {
            text-align: center;
            margin-top: 10px;
        }
             

         body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 20px;
        }
        .container {
          
            padding: 20px;
           
            max-width: 800px;
            margin: 0 auto;
        }
        .header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 20px;
        }
        .right-side, .center, .left-side {
            flex: 1;
        }
        .center {
            text-align: center;
        }
        .left-side {
            text-align: right;
        }
        h1 {
            font-size: 18px;
            margin: 0 0 10px 0;
        }
        p {
            margin: 5px 0;
            font-size: 14px;
        }
        .form-field {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
        }
        .form-field span:first-child {
            font-weight: bold;
        }
        .form-field span:last-child {
            
            flex: 1;
            margin-right: 10px;
        }

         .attendance-header {
            font-family: Arial, sans-serif;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
           
            max-width: 600px;
            margin: 20px auto;
        }
        .attendance-item {
            display: flex;
            align-items: center;
        }
        .attendance-label {
            font-weight: bold;
            margin-left: 5px;
        }
        .attendance-value {
            border: 1px solid #000;
            padding: 2px 5px;
            min-width: 20px;
            text-align: center;
        }

    </style>
</head>
<body>
 <div class="container">
        <div class="header">
            <div class="right-side">
                

                  <h1>المملكة العربية السعودية</h1>
                <h1>وزارة الداخلية</h1>
                <h1>المديرية العامة للجوازات</h1>
            </div>
            <div class="center">
                <h1>بسم الله الرحمن الرحيم</h1>
                <h1>رقم البيان</h1>
                <h1>( 1 )</p>
                <h1>وسيلة السفر : Air</h1>
            </div>
            <div class="left-side">
               <h1>رقم السائق :</h1>
                <h1>رقم السيارة و نوعها :</h1>
                <h1>جنسية السيارة :</h1>
            </div>
        </div>
        <div class="form-fields">
            <div class="form-field">
                <h1>اسم المرخص له :</h1>
                <span></span>
            </div>
            <div class="form-field">
                <h1>اسم الوكيل :</h1>
                <span></span>
            </div>
            <div class="form-field">
                <h1>رقم الترخيص :</h1>
                <span></span>
            </div>
        </div>
    </div>


    <div class="section-title">بيانات المسافرين</div>
    <table>
        <tr>
                    <th>الاسم</th>
            <th>الجنسية</th>
              <th>المرافقون</th>
            <th>تاريخ الميلاد</th>
            <th>كرت جسر</th>

            <th>اسم الام</th>
               <th>المحرم</th>
                   <th>رقم الجواز</th>


        </tr>
     [reservations]
    </table>





      <div class="attendance-header">
        <div class="attendance-item">
            <span class="attendance-label">مجموع المعتمرين :</span>
            <span class="attendance-value">---</span>
        </div>
        <div class="attendance-item">
            <span class="attendance-label">عدد الذكور :</span>
            <span class="attendance-value">---</span>
        </div>
        <div class="attendance-item">
            <span class="attendance-label">عدد الإناث :</span>
            <span class="attendance-value">---</span>
        </div>
        <div class="attendance-item">
            <span class="attendance-label">عدد المرافقين :</span>
            <span class="attendance-value">---</span>
        </div>
        <div class="attendance-item">
            <span class="attendance-label">الختم و التوقيع</span>
        </div>
    </div>

    <div class="footer">
    <p>
        POWERED BY: 
        <a href="https://safavisa.com" style="color: blue; text-decoration: none;">
            SAFAVISA.COM
        </a>
    </p>

   
</div>
</body>
</html>`