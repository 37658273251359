import { useState } from 'react';
// React-Router-Dom
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
// Assets
import { ReactComponent as PlusIcon } from "assets/images/serviceBuilder/plus.svg";
// --------------------------------------------------------------------------------------------------------

const ServicesMainTabs = ({tabs}) => {

  const history = useHistory()

  const { serviceName } = useParams();

  const [selectedTab, setSelectedTab] = useState(serviceName)

  // --------- JSX Code -----------
  return (
    <div className='main-tabs-holder'>
      {
        tabs?.map(tab =>
          <div
            key={tab?.id}
            className={`${selectedTab === tab?.url ? 'active-tab-box' : 'tab-box'} `}
            onClick={() => {
              setSelectedTab(tab?.url)
              history.push(`/website/service-builer/add-service/${tab?.url}`)
            }}
          >
            <div className='tab'>
              <span>{tab?.icon}</span>
              <p>{tab?.title}</p>
            </div>
            {selectedTab !== tab?.url ? <PlusIcon /> : <div style={{ width: '20px', height: '20px' }} />}
          </div>
        )
      }
    </div>
  )
}

export default ServicesMainTabs;