import { useRef, useState } from 'react'
// Translation
import Locale from 'translations';
// ReactStarp
import { Spinner } from 'reactstrap';
// Components
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';
import UploadFile from 'components/UploadFile';
// Context
import { useSBSState } from 'context/global';
// Shared Components
import MultiTagsTextField from '../shared/MultiTagsTextField';
import HotelPricesTaxes from '../shared/HotelPricesTaxes';
// Servicies
import { getHotelMapToolHotels } from 'services/productbuilder';
import { fetchCities } from 'services/lookups';
// helper
import validate, { isFormValid } from 'helpers/validate';
// Assets
import downIcon from "assets/images/serviceBuilder/chevron-down.svg";
// Helper
import generateUniqueID from 'helpers/generateUniqueID';
import DefualtPricesTaxes from '../shared/DefualtPricesTaxes';

// ----------------------------------------------------------------------------------------------------

const ServiceBuilderTransferForm = () => {
  const { Service_Builder } = Locale

  const refundList = [
    { name: Service_Builder?.yes, id: 1 },
    { name: Service_Builder?.no, id: 0 },
  ]

  const hotelStarsList = [
    { name: 1, id: 1 },
    { name: 2, id: 2 },
    { name: 3, id: 3 },
    { name: 4, id: 4 },
    { name: 5, id: 5 },
  ]

  const { locale, allCountries } = useSBSState()

  const [transferData, setHotelData] = useState({
    name: {
      en: "",
      ar: ""
    },
    // country_id: '',
    // city_id: '',
    country: '',
    city: '',
    hotel_name: {
      en: "",
      ar: ""
    },
    trip_type: 'umrah',
    hotel_address: "",
    hotel_amenities: [],
    // is_refundable: 1,
    refundable: [],
    policy: {
      en: "",
      ar: ""
    },
    cancellation: {
      en: "",
      ar: ""
    },
    description: {
      en: "",
      ar: ""
    },
    images: [],
    nights_count: 1,
    price: '',
    currency: "SAR",
    taxes: [
      {
        "name": "tax 1",
        "type": "percentage",
        "value": 20
      },
      {
        "name": "tax 2",
        "type": "fixed",
        "value": 20
      }
    ]
  });

  let goingToPrevValue = useRef(null);

  const [hotelAmenity, setHotelAmenity] = useState('')

  const [showPricing, setShowPricing] = useState(true)

  const [hotelsOnSearch, setHotelOnSearch] = useState({ children: 0 });

  const [citiesList, setCitiesList] = useState([])

  const [hotelsList, setHotelsList] = useState([]);

  const [errors, setErrors] = useState({});

  const [spin, setSpin] = useState(false);


  function handleFlightInputsChanges({ key, value }, lang) {
    let hotelDataClone = { ...transferData }
    if (key === 'country') {
      fetchCity(value?.id)
    }
    if (lang) {
      hotelDataClone[`${key}`][`${lang}`] = value
      setHotelData({ ...hotelDataClone })
    } else {
      hotelDataClone[`${key}`] = value;
      setHotelData({ ...hotelDataClone })
    }

  }

  const handleAddImage = (imagesURL) => {
    setHotelData({ ...transferData, images: imagesURL })
  }

  function checkFormErrors() {
    let submitError = {};
    Object.keys(transferData).forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: transferData[key] },
          {
            required: true,
            email: key === "email",
            password: key === "password",
            min: key === "password" ? 8 : key === "name" || key === "companyName" ? 3 : "",
          }
        )
      }
    })
    setErrors(() => submitError);
    return submitError;
  }


  const updatePriceAndTaxes = (data) => {
    let hotelDataClone = { ...transferData }
    hotelDataClone['currency'] = data?.serviceCurrency?.currency_code;
    hotelDataClone['taxes'] = [...data?.taxes];
    hotelDataClone['price'] = data?.servicePrice;
    setHotelData({ ...hotelDataClone })
  }

  // AutoCompleteField
  // get hotels list based on user input
  async function getHotelsList(inputValue) {
    if (inputValue.length > 2) {
      let params = {
        name: inputValue,
        country_id: transferData?.country?.id,
        city_id: transferData?.city?.id,
      }
      const hotelsRes = await getHotelMapToolHotels(params);
      let result = [];
      hotelsRes?.data?.forEach((item) => {
        result.push({
          ...item,
          name: item.name?.[locale] || item.name,
          value: item.id,
          label: item.name?.[locale] || item.name,
        });
      });
      setHotelsList(result);
    }
  };

  const fetchCity = async (countryId) => {
    const citiesRes = await fetchCities(countryId);
    setCitiesList(citiesRes);
  };

  function clearSearchText(e, key) {
    goingToPrevValue.current = hotelsOnSearch[key];
    const value = e.target.value;
    if (value.length > 0) {
      setHotelOnSearch({ ...hotelsOnSearch, [key]: null });
    }
  }

  async function submit(e) {
    e.preventDefault();
    let formErrors = checkFormErrors();
    setSpin(true);
    if (!isFormValid(formErrors)) {
      setSpin(false);
      return
    }
    setSpin(false);
  }

  // console.log(hotelData)

  // -------- JSX Code ---------
  return (
    <div >
      <h1 className='form-title'>
        {Service_Builder?.select_transfer_details}
      </h1>

      <form onSubmit={submit} className="mt-4 ">

        {/* Transfer Service Form  */}
        <div className='row form-holder mx-2 mb-5'>


          {/* Trip Type */}
          <div className="col-2 mt-2">
            <label
              htmlFor={`umrah`}
              className={`${transferData.trip_type === 'umrah' ? 'active-tour-tab' : 'tour-tab'} pointer-event`}
            >
              <input
                type="radio"
                id={`umrah`}
                name={`trip_type`}
                value="umrah"
                checked={transferData?.trip_type === 'umrah'}
                onChange={(e) => handleFlightInputsChanges({ key: 'trip_type', value: e?.target?.value })}
              />
              <p className='taxes-type-value'> {Service_Builder?.umrah}</p>
            </label>
          </div>

          {/* Trip Type */}
          <div className="col-2 mt-2">
            <label
              htmlFor={`tourism`}
              className={`${transferData.trip_type === 'tourism' ? 'active-tour-tab' : 'tour-tab'}`}
            >
              <input
                type="radio"
                id={`tourism`}
                name={`trip_type`}
                value="tourism"
                checked={transferData?.trip_type === 'tourism'}
                onChange={(e) => handleFlightInputsChanges({ key: 'trip_type', value: e?.target?.value })}
              />
              <p className='taxes-type-value'>  {Service_Builder?.tourism}</p>
            </label>
          </div>

          <div className="col-8 mt-2" />

          {/* Service Name en */}
          <div className="col-4 mt-2">
            <TextField
              type="text"
              hasLabel={false}
              placeholder={Service_Builder?.service_name_en}
              name="name"
              id="name"
              value={transferData?.name["en"]}
              onChange={(e) => {
                handleFlightInputsChanges({ key: 'name', value: e?.target?.value }, 'en')
                // setErrors({
                //   ...errors,
                //   ...validate(
                //     { name: "email", value: e.target.value },
                //     { required: true, email: true }
                //   ),
                // });
              }}
              color={errors?.email?.required || errors?.email?.email ? "danger" : ""}
              errors={errors?.email}
            />
          </div>

          {/* Service Name ar */}
          <div className="col-4 mt-2">
            <TextField
              type="text"
              hasLabel={false}
              placeholder={Service_Builder?.service_name_ar}
              name="name"
              id="name"
              value={transferData?.name["ar"]}
              onChange={(e) => {
                handleFlightInputsChanges({ key: 'name', value: e?.target?.value }, 'ar')
                // setErrors({
                //   ...errors,
                //   ...validate(
                //     { name: "password", value: e.target.value },
                //     { required: true, password: true, min: 8 }
                //   ),
                // });
              }}
            // color={errors?.password?.required || errors?.password?.password || errors?.password?.min ? "danger" : ""}
            // errors={errors?.password}
            />
          </div>

          <div className="col-4 mt-2" />

          {/* Cycle */}
          {transferData?.trip_type === 'umrah' &&
            <div className="col-4 mt-2">
              <SelectField
                hasLabel={false}
                placeholder={Service_Builder?.cycle}
                id="cycle"
                name="cycle"
                value={transferData?.cycle?.name}
                options={allCountries}
                onChange={(e) => handleFlightInputsChanges({ key: 'cycle', value: e })}
              />
            </div>
          }

          {/* Country */}
          {transferData?.trip_type === 'tourism' &&
            <div className="col-2 mt-2">
              <SelectField
                hasLabel={false}
                placeholder={Service_Builder?.country}
                id="country"
                name="country"
                value={transferData?.country?.name}
                options={allCountries}
                onChange={(e) => handleFlightInputsChanges({ key: 'country', value: e })}
              />
            </div>
          }

          {/* City */}
          {transferData?.trip_type === 'tourism' &&
            <div className="col-2 mt-2">
              <SelectField
                hasLabel={false}
                placeholder={Service_Builder?.city}
                id="city"
                name="city"
                value={transferData?.city?.name}
                options={citiesList}
                onChange={(e) => handleFlightInputsChanges({ key: 'city', value: e })}
              />
            </div>
          }

          {/* Type */}
          <div className="col-4 mt-2">
            <SelectField
              hasLabel={false}
              placeholder={Service_Builder?.type}
              id="type"
              name="type"
              value={transferData?.type?.name}
              options={citiesList}
              onChange={(e) => handleFlightInputsChanges({ key: 'type', value: e })}
            />
          </div>

          {/* Model */}
          <div className="col-4 mt-2">
            <SelectField
              hasLabel={false}
              placeholder={Service_Builder?.model}
              id="model"
              name="model"
              value={transferData?.model?.name}
              options={citiesList}
              onChange={(e) => handleFlightInputsChanges({ key: 'model', value: e })}
            />
          </div>

          {/*Transfer Car Capacity  */}
          <div className="col-4 mt-2">
            <TextField
              type="text"
              hasLabel={false}
              placeholder={Service_Builder?.car_capacity}
              name="car_capacity"
              id="car_capacity"
              value={transferData?.car_capacity}
              onChange={(e) => {
                handleFlightInputsChanges({ key: 'car_capacity', value: e?.target?.value })
                // setErrors({
                //   ...errors,
                //   ...validate(
                //     { name: "email", value: e.target.value },
                //     { required: true, email: true }
                //   ),
                // });
              }}
              color={errors?.email?.required || errors?.email?.email ? "danger" : ""}
              errors={errors?.email}
            />
          </div>

          {/* Transfer With Driver  */}
          <div className="col-4 mt-2">
            <SelectField
              hasLabel={false}
              placeholder={Service_Builder?.with_driver}
              id="hotel_stars"
              name="hotel_stars"
              value={transferData?.hotel_stars}
              options={hotelStarsList}
              onChange={(e) => handleFlightInputsChanges({ key: 'hotel_stars', value: e?.id })} />
          </div>

          {/* Transfer Refund*/}
          <div className="col-4 mt-2 align-self-baseline">
            <SelectField
              hasLabel={false}
              placeholder={Service_Builder?.refundable}
              id="country"
              name="country"
              value={transferData?.refundable?.name}
              options={refundList}
              onChange={(e) => handleFlightInputsChanges({ key: 'refundable', value: e })}
            />
          </div>

          {/* Transfer Description*/}
          <div className="col-6 mt-2">
            <TextAreaField
              hasLabel={false}
              height={'126px'}
              placeholder={Service_Builder?.description}
              value={transferData?.description['en']}
              onChange={(e) => handleFlightInputsChanges({ key: 'description', value: e?.target?.value }, 'en')}
            />
          </div>

          {/* Transfer Policy*/}
          <div className="col-6 mt-2">
            <TextAreaField
              hasLabel={false}
              height={'126px'}
              // label={inventory.messages.cancellationReason}
              placeholder={Service_Builder?.policy}
              value={transferData?.policy['en']}
              onChange={(e) => handleFlightInputsChanges({ key: 'policy', value: e?.target?.value }, 'en')}
            />
          </div>

          {/* Transfer Iamges*/}
          <div className="col-12 mt-2">
            <p className='font-bold mb-3'>{Service_Builder?.add_images}</p>
            <div className='images-holder d-flex  align-items-center '>

              <UploadFile
                id={`upload-hotel-image-${generateUniqueID()}`}
                // label={packagePlanner.attractionImageGallery}
                onChange={(images) => {
                  handleAddImage(images)
                  // handleAttractionInputs({ key: "photos", value });
                  //   setErrors({
                  //     ...errors,
                  //     ...validate(
                  //       {
                  //         name: "photos",
                  //         value: value.length,
                  //       },
                  //       { required: true }
                  //     ),
                  //   });
                  // }}
                  // value={attractionData.photos}
                  // errorMsg={
                  //   errors?.photos?.required
                  //     ? inventory.viewReservation.Photos + " " + commons.isRequired
                  //     : false
                }}
                value={transferData.images}
              />


            </div>
          </div>

        </div>

        {/* Show Pricing Checkbox*/}
        <div className="d-flex align-items-center mb-3">
          <input
            type="Checkbox"
            name={"eheck1"}
            id={"exampleCheck1"}
            checked={showPricing}
            onChange={() => setShowPricing(!showPricing)}
          />

          <label
            className="form-check-label pointer font-bold text-caption mx-2 rememberme-label"
            htmlFor={"exampleCheck1"}
          >
            {Service_Builder?.select_pricing}
          </label>
        </div>

        {/* Hotel Pricing and Taxes*/}
        {showPricing && <DefualtPricesTaxes onChange={(data) => updatePriceAndTaxes(data)} />}

        {/* Save Button */}
        <div className="col-12 d-flex justify-content-end gap-10 mt-3">
          <button type="submit" className="submit-btn "
            disabled={spin}
          >
            <span className="btn-overlay"></span>
            <span className="text d-flex align-items-center gap-10 justify-content-center">
              {spin ? <Spinner color="light" size="sm" /> : null}
              {Service_Builder?.save}
            </span>
          </button>

        </div>

      </form>
    </div>
  )
}

export default ServiceBuilderTransferForm