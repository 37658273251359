import { useRef, useState } from 'react'
// react-router-dom
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// Translation
import Locale from 'translations';
// ReactStarp
import { Spinner } from 'reactstrap';
// Components
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';
import UploadFile from 'components/UploadFile';
// Context
import { useSBSState } from 'context/global';
// Shared Components
import MultiTagsTextField from '../shared/MultiTagsTextField';
import HotelPricesTaxes from '../shared/HotelPricesTaxes';
// Servicies
import { getHotelMapToolHotels } from 'services/productbuilder';
import { fetchCities } from 'services/lookups';
// helper
import validate, { isFormValid } from 'helpers/validate';
// Assets
import downIcon from "assets/images/serviceBuilder/chevron-down.svg";
// Helper
import generateUniqueID from 'helpers/generateUniqueID';
import { addHotelService } from 'services/serviveBuilder';
// ----------------------------------------------------------------------------------------------------


const ServiceBuilderHotelForm = () => {

  // *** Hooks
  const { Service_Builder } = Locale

  const { locale, allCountries } = useSBSState()

  const history = useHistory()

  let goingToPrevValue = useRef(null);

  // *** Dummy Data
  const refundList = [
    { name: Service_Builder?.yes, id: true },
    { name: Service_Builder?.no, id: false },
  ]

  const hotelStarsList = [
    { name: 1, id: '1' },
    { name: 2, id: '2' },
    { name: 3, id: '3' },
    { name: 4, id: '4' },
    { name: 5, id: '5' },
  ]

  // *** State
  const [hotelData, setHotelData] = useState({
    // name: {
    //   en: "",
    //   ar: ""
    // },
    name_en: '',
    name_ar: '',
    country: '',
    city: '',
    // hotel_name: {
    //   en: "",
    //   ar: ""
    // },
    hotel_name_en: '',
    hotel_name_ar: '',
    hotel_stars: '',
    hotel_address: "",
    hotel_amenities: [],
    refundable: '',
    // policy: {
    //   en: "",
    //   ar: ""
    // },
    policy_en: '',
    policy_ar: '',
    // cancellation: {
    //   en: "",
    //   ar: ""
    // },
    cancellation_en: '',
    cancellation_ar: '',
    // description: {
    //   en: "",
    //   ar: ""
    // },
    description_en: '',
    description_ar: '',
    images: [],
    nights_count: 1,
    price: [
      {
        "room_type": 1,
        "adult_price": 1000,
        "child_price": 200
      },
      {
        "room_type": 2,
        "adult_price": 500,
        "child_price": 200
      }
    ],
    currency: "SAR",
    taxes: [
      {
        "name": "tax 1",
        "type": "percentage",
        "value": 20
      },
      {
        "name": "tax 2",
        "type": "fixed",
        "value": 20
      }
    ]
  });

  const [hotelAmenity, setHotelAmenity] = useState('')

  const [showPricing, setShowPricing] = useState(true)

  const [hotelsOnSearch, setHotelOnSearch] = useState({ children: 0 });

  const [citiesList, setCitiesList] = useState([])

  const [hotelsList, setHotelsList] = useState([]);

  const [errors, setErrors] = useState({});


  // *** Function Logic
  function handleHotelInputsChanges({ key, value }) {
    let hotelDataClone = { ...hotelData }
    if (key === 'country') {
      fetchCity(value?.id)
    }
    hotelDataClone[`${key}`] = value;
    setHotelData({ ...hotelDataClone })
  }

  // const handleAddImage = (imagesURL) => {
  //   setHotelData({ ...hotelData, images: imagesURL })
  // }

  function checkFormErrors() {
    let submitError = {};
    Object.keys(hotelData).forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: hotelData[key] },
          {
            required: true,
            email: key === "email",
            password: key === "password",
            min: key === "password" ? 8 : key === "name" || key === "companyName" ? 3 : "",
          }
        )
      }
    })
    setErrors(() => submitError);
    return submitError;
  }

  const handleAddHotelAmenity = () => {
    const hotelDataClone = { ...hotelData }
    if (hotelAmenity !== '') {
      setHotelData({
        ...hotelDataClone,
        hotel_amenities: [...hotelDataClone?.hotel_amenities, hotelAmenity]
      })
      setErrors({
        ...errors,
        ...validate(
          { name: "hotel_amenities", value: hotelData?.hotel_amenities?.length },
          { required: true }
        ),
      });
      setHotelAmenity('')
    }
  }

  const handleRemoveHotelAmenity = (index) => {
    const hotelDataClone = { ...hotelData }
    hotelDataClone?.hotel_amenities?.splice(index, 1)
    setHotelData({ ...hotelDataClone })
  }

  const updatePriceAndTaxes = (data) => {
    let hotelDataClone = { ...hotelData }
    hotelDataClone['currency'] = data?.serviceCurrency?.currency_code;
    hotelDataClone['nights_count'] = data?.price_type === 'price_per_night' ? 1 : Number(data?.nights);
    hotelDataClone['taxes'] = [...data?.taxes];
    hotelDataClone['price'] = [...data?.rooms];
    setHotelData({ ...hotelDataClone })
  }

  // AutoCompleteField hotels list
  async function getHotelsList(inputValue) {
    if (inputValue.length > 2) {
      let params = {
        name: inputValue,
        country_id: hotelData?.country?.id,
        city_id: hotelData?.city?.id,
      }
      const hotelsRes = await getHotelMapToolHotels(params);
      let result = [];
      hotelsRes?.data?.forEach((item) => {
        result.push({
          ...item,
          name: item.name?.[locale] || item.name,
          value: item.id,
          label: item.name?.[locale] || item.name,
        });
      });
      setHotelsList(result);
    }
  };

  const fetchCity = async (countryId) => {
    const citiesRes = await fetchCities(countryId);
    setCitiesList(citiesRes);
  };

  function clearSearchText(e, key) {
    goingToPrevValue.current = hotelsOnSearch[key];
    const value = e.target.value;
    if (value.length > 0) {
      setHotelOnSearch({ ...hotelsOnSearch, [key]: null });
    }
  }

  async function submit(e) {
    e.preventDefault();
    let formErrors = checkFormErrors();

    if (!isFormValid(formErrors)) {
      // let requestBody = {
      //   name: { ...hotelData?.name },
      //   country_id: hotelData?.country?.id,
      //   city_id: hotelData?.city?.id,
      //   hotel_name: { ...hotelData?.hotel_name },
      //   hotel_stars: hotelData?.hotel_stars,
      //   hotel_address: hotelData?.hotel_address,
      //   hotel_amenities: hotelData?.hotel_amenities,
      //   is_refundable: hotelData?.refundable?.id,
      //   policy: { ...hotelData?.policy },
      //   cancellation: { ...hotelData?.cancellation },
      //   description: { ...hotelData?.description },
      //   images: hotelData?.images,
      //   nights_count: hotelData?.nights_count,
      //   price: hotelData?.price,
      //   currency: hotelData?.currency,
      //   taxes: hotelData?.taxes
      // }
      // const response = await addHotelService(requestBody);
      // console.log(response)
      // if (response?.status === 200 || response?.status === 201) {
      //   setSpin(false);
      //   history.push('/website/service-builer')
      // }
      return
    }

  }

  console.log(hotelData)

  // -------- JSX Code ---------
  return (
    <div >
      <h1 className='form-title'>
        {Service_Builder?.select_hotel_details}
      </h1>

      <form onSubmit={submit} className="mt-4 ">

        {/* Hotel Service Form  */}
        <div className='row form-holder mx-2 mb-5'>

          {/* Service Name en */}
          <div className="col-4 mt-2">
            <TextField
              type="text"
              hasLabel={false}
              label={Service_Builder?.service_name_en}
              placeholder={Service_Builder?.service_name_en}
              name="name_en"
              id="name_en"
              value={hotelData?.name_en}
              onChange={(e) => {
                handleHotelInputsChanges({ key: 'name_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "name_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.name_en?.required || errors?.name_en ? "danger" : ""}
              errors={errors?.name_en}
            />
          </div>

          {/* Service Name ar */}
          <div className="col-4 mt-2">
            <TextField
              type="text"
              hasLabel={false}
              label={Service_Builder?.service_name_ar}
              placeholder={Service_Builder?.service_name_ar}
              name="name_ar"
              id="name_ar"
              value={hotelData?.name_ar}
              onChange={(e) => {
                handleHotelInputsChanges({ key: 'name_ar', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "name_ar", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.name_ar?.required || errors?.name_ar ? "danger" : ""}
              errors={errors?.name_ar}
            />
          </div>

          {/* Country */}
          <div className="col-2 mt-2">
            <SelectField
              hasLabel={false}
              label={Service_Builder?.country}
              placeholder={Service_Builder?.country}
              id="country"
              name="country"
              value={hotelData?.country?.name}
              options={allCountries}
              onChange={(e) => {
                handleHotelInputsChanges({ key: 'country', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "country", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.country?.required ? "danger" : ""}
              errors={errors?.country}
            />
          </div>

          {/* City */}
          <div className="col-2 mt-2">
            <SelectField
              disabled={citiesList?.length === 0}
              hasLabel={false}
              label={Service_Builder?.city}
              placeholder={Service_Builder?.city}
              id="city"
              name="city"
              value={hotelData?.city?.name}
              options={citiesList}
              onChange={(e) => {
                handleHotelInputsChanges({ key: 'city', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "city", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.city?.required ? "danger" : ""}
              errors={errors?.city}
            />
          </div>

          {/* Hotel Name */}
          <div className="col-4 mt-2">
            <div className="form-group">
              <AutoCompleteField
                hasLabel={false}
                disabled={!hotelData?.city}
                labelInner={false}
                isImage={false}
                // image={downIcon}
                label={Service_Builder?.hotel_name}
                isSearchable={true}
                placeholder={Service_Builder?.hotel_name}
                listAuto={hotelsList}
                setListAuto={setHotelsList}
                getListAuto={getHotelsList}
                value={hotelsOnSearch?.name || ""}
                // flag={flights.from?.country?.flag}
                onFocus={(e) => clearSearchText(e, "name")}
                onBlur={() =>
                  setHotelOnSearch({
                    ...hotelsOnSearch,
                    name: goingToPrevValue.current,
                  })
                }
                onChange={(e) => {
                  setHotelOnSearch({
                    ...hotelsOnSearch,
                    name: e,
                  });
                }}
                onSelectValue={(selectedObj) => {
                  setHotelOnSearch({ ...selectedObj });

                  handleHotelInputsChanges({ key: 'hotel_name_en', value: selectedObj?.name });
                  handleHotelInputsChanges({ key: 'hotel_stars', value: hotelStarsList?.find(e => e?.id === selectedObj?.rating)?.id });
                  handleHotelInputsChanges({ key: 'hotel_amenities', value: selectedObj?.facilities });
                  setErrors({
                    ...errors,
                    ...validate({ name: "hotel_name_en", value: selectedObj }, { required: true }),
                  });
                }}
                color={errors?.hotel_name_en?.required ? "danger" : ""}
                errors={errors?.hotel_name_en}
              />
              <i class="fas fa-exchange-alt d-none"></i>
            </div>
          </div>

          {/* Hotel Star */}
          <div className="col-4 mt-2">
            <SelectField
              disabled={!hotelsOnSearch?.id}
              hasLabel={false}
              label={Service_Builder?.star}
              placeholder={Service_Builder?.star}
              id="hotel_stars"
              name="hotel_stars"
              value={hotelData?.hotel_stars}
              options={hotelStarsList}
              onChange={(e) => {
                handleHotelInputsChanges({ key: 'hotel_stars', value: e?.id })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "hotel_stars", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.hotel_stars?.required ? "danger" : ""}
              errors={errors?.hotel_stars}
            />
          </div>

          {/* Hotel Address*/}
          <div className="col-4 mt-2">
            <TextField
              disabled={!hotelsOnSearch?.id}
              type="text"
              hasLabel={false}
              label={Service_Builder?.hotel_address}
              placeholder={Service_Builder?.hotel_address}
              name="hotel_address"
              id="hotel_address"
              value={hotelData?.hotel_address}
              onChange={(e) => {
                handleHotelInputsChanges({ key: 'hotel_address', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "hotel_address", value: e.target.value },
                    { required: true, email: true }
                  ),
                });
              }}
              color={errors?.hotel_address?.required || errors?.hotel_address ? "danger" : ""}
              errors={errors?.hotel_address}
            />
          </div>

          {/* Hotel Amenities*/}
          <div className="col-8 mt-2">
            <MultiTagsTextField
              hasLabel={true}
              type="text"
              label={Service_Builder?.hotel_amenities}
              placeholder={Service_Builder?.wifi_free}
              name="hotel_amenities"
              id="hotel_amenities"
              value={hotelAmenity}
              onChange={(e) => {
                setHotelAmenity(e.target.value)
              }}
              onClickAddButton={handleAddHotelAmenity}
              onEnter={(e) => {
                if (e.key === "Enter")
                  handleAddHotelAmenity();
              }}
              color={errors?.hotel_amenities?.required || errors?.hotel_amenities ? "danger" : ""}
              errors={errors?.hotel_amenities}
            />
            <div className='d-flex flex-wrap mt-2 '>
              {hotelData?.hotel_amenities?.map((amenity, index) =>
                <div className='tag mx-1' >
                  {amenity}
                  <span
                    className='pointer-event font-bold'
                    onClick={() => handleRemoveHotelAmenity(index)}
                  >
                    X
                  </span>
                </div>
              )}
            </div>
          </div>

          {/* Hotel Refund*/}
          <div className="col-4 mt-2 align-self-baseline">
            <SelectField
              hasLabel={false}
              label={Service_Builder?.refundable}
              placeholder={Service_Builder?.refundable}
              id="country"
              name="country"
              value={hotelData?.refundable?.name}
              options={refundList}
              onChange={(e) => {
                handleHotelInputsChanges({ key: 'refundable', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "refundable", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.refundable?.required ? "danger" : ""}
              errors={errors?.refundable}
            />
          </div>

          {/* Hotel Description*/}
          <div className="col-12 mt-2">
            <TextAreaField
              hasLabel={false}
              height={'126px'}
              placeholder={Service_Builder?.description}
              value={hotelData?.description_en}
              onChange={(e) => {
                handleHotelInputsChanges({ key: 'description_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "description_en", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.description_en?.required || errors?.description_en ? "danger" : ""}
              errors={errors?.description_en}
            />
          </div>

          {/* Hotel Policy*/}
          <div className="col-6 mt-2">
            <TextAreaField
              hasLabel={false}
              height={'126px'}
              label={Service_Builder?.policy}
              placeholder={Service_Builder?.policy}
              value={hotelData?.policy_en}
              onChange={(e) => handleHotelInputsChanges({ key: 'policy_en', value: e?.target?.value })}
            />
          </div>

          {/* Hotel Canceltion*/}
          <div className="col-6 mt-2">
            <TextAreaField
              hasLabel={false}
              height={'126px'}
              label={Service_Builder?.cancellation}
              placeholder={Service_Builder?.cancellation}
              value={hotelData?.cancellation_en}
              onChange={(e) => handleHotelInputsChanges({ key: 'cancellation_en', value: e?.target?.value })}
            />
          </div>

          {/* Hotel Iamges*/}
          <div className="col-12 mt-2">
            <p className='font-bold mb-3'>{Service_Builder?.add_images}</p>
            <div className='images-holder d-flex  align-items-center '>
              <UploadFile
                id={`upload-hotel-image-${generateUniqueID()}`}
                // label={packagePlanner.attractionImageGallery}
                onChange={(images) => {
                  // handleAddImage(images)
                  handleHotelInputsChanges({ key: 'images', value: images })
                  // handleAttractionInputs({ key: "photos", value });
                  //   setErrors({
                  //     ...errors,
                  //     ...validate(
                  //       {
                  //         name: "photos",
                  //         value: value.length,
                  //       },
                  //       { required: true }
                  //     ),
                  //   });
                  // }}
                  // value={attractionData.photos}
                  // errorMsg={
                  //   errors?.photos?.required
                  //     ? inventory.viewReservation.Photos + " " + commons.isRequired
                  //     : false
                }}
                value={hotelData.images}
              />

            </div>
          </div>

        </div>

        {/* Show Pricing Checkbox*/}
        <div className="d-flex align-items-center mb-3">
          <input
            type="Checkbox"
            name={"eheck1"}
            id={"exampleCheck1"}
            checked={showPricing}
            onChange={() => setShowPricing(!showPricing)}
          />

          <label
            className="form-check-label pointer font-bold text-caption mx-2 rememberme-label"
            htmlFor={"exampleCheck1"}
          >
            {Service_Builder?.select_pricing}
          </label>
        </div>

        {/* Hotel Pricing and Taxes*/}
        {showPricing && <HotelPricesTaxes onChange={(data) => updatePriceAndTaxes(data)} />}

        {/* Save Button */}
        <div className="col-12 d-flex justify-content-end gap-10 mt-3">
          <button type="submit" className="submit-btn ">
            <span className="btn-overlay"></span>
            <span className="text d-flex align-items-center gap-10 justify-content-center">
              {Service_Builder?.save}
            </span>
          </button>

        </div>

      </form>
    </div>
  )
}

export default ServiceBuilderHotelForm