import { useSBSState } from "context/global";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Company from "./shared/company";
import { fetchMarketPackagesDetails, PackageCheckAvailability } from "services/marketplace";
import Locale from 'translations';
import ProductItinerary from './ProductItinerary';
import { ReactComponent as RequiredDocIcon } from "assets/images/market-place/required-document-text.svg";
import { ReactComponent as DollarCircleIcon } from "assets/images/market-place/dollar-circle.svg";
import { useMarketplaceDispatch } from 'context/marketplace';
import { useHistory } from 'react-router-dom';
import TextField from 'components/Form/TextField/TextField';
import validate from "helpers/validate";
import { itineraryElementsIds } from '../helpers/formatServicesItems';
import { calculateBreakdownPackage, calculateFullPackage } from '../helpers/calculatePackagePrice';
import { formatPrice } from 'helpers/utils';
import GuestsPerRoom from 'modules/CRM/Main/components/GuestsPerRoom';
import GuestsPerPax from 'modules/CRM/Main/components/GuestsPerPax';
import PackagePricing from './PackagePricing';
import PackageItemsView from './PackageItemsView';
import SelectField from 'components/Form/SelectField/SelectField';
import moment from 'moment';



const imageUrl = process.env.REACT_APP_API_URL + "/v1/files/fetch/";

export default function PackageDetails() {
	const { locale } = useSBSState();
	const { packages, marketPlace, productsBuilder, inventory } = Locale;
	const packagesDispatch = useMarketplaceDispatch();
	const history = useHistory();
	const { status, uuid } = useParams();
	const itineraryId = "tab-101";
	const isMobile = false;


	// ** states
	const [activeTab, setActiveTab] = useState(itineraryId);
	const [reservationDetails, setReservationDetails] = useState();
	const [paxCount, setPaxCount] = useState({
		adultPax: 0,
		childsPax: 0,
	});

	const initialStartDate = reservationDetails?.itinerary[0]?.start_period_date
	const initialEndDate = reservationDetails?.itinerary[1]?.end_period_date
	const initialDuration = moment(initialEndDate?.split('-')).diff(initialStartDate?.split('-'), 'days')

	const [hotelsSharedRooms, setHotelsSharedRooms] = useState([]);
	const [hotelsPrivateRooms, setHotelsPrivateRooms] = useState([]);

	const [errors, setErrors] = useState({});
	const [isNavDisappeared, setIsNavDisappeared] = useState(false);
	const [categoryType, setCategoryType] = useState(null);

	const [daysList, setDaysList] = useState([])
	const [selectedDate, setSelectedDate] = useState(null)

	function scrollToElement(eleId) {
		if (document && eleId) {
			document?.getElementById(eleId)?.scrollIntoView({ behavior: "smooth" });
		}
	}

	async function CheckAvailability() {
		let hotelsRooms = categoryType?.value === "shared" ? hotelsSharedRooms : hotelsPrivateRooms;
		let payloadData = {
			product_uuid: uuid,
			reservation_category: categoryType?.value,
			pax: {
				adults_count: +paxCount.adultPax || 0,
				children_count: +paxCount.childsPax || 0,
			},
			hotel_rooms: hotelsRooms?.map(hotel => {
				return hotel?.pax?.map(paxItem => ({
					product_hotel_id: hotel?.hotelId,
					room_type: paxItem?.roomType?.value,
					room_category: paxItem?.roomCategory?.value,
					meal_type: paxItem?.roomMeal?.value,

					children_count: +paxItem?.childrenCount || 0,
					...(categoryType?.value === "private" && { rooms_count: 0 }),
					...(categoryType?.value === "shared" && { adults_count: +paxItem?.adultsCount }),
				}))
			}).flat(1)
		};

		const checkFlightAbaliabilityRes = await PackageCheckAvailability(payloadData);

		if (checkFlightAbaliabilityRes.status === 200) {
			packagesDispatch({
				type: "setPackagesDetails",
				payload: {
					reservationDetails,
					totalPrice: calcTotalPrices,
					hotelsRooms,
					paxCount,
					reservationCategory: categoryType
				},
			});

			history.push(`/${status}/checkout-package/${uuid}`);
		}
	}

	const productItemsTabs = useMemo(() => {
		let items = [];
		const productHasItinerary = reservationDetails?.itinerary && reservationDetails?.itinerary?.length > 0;
		if (productHasItinerary) {
			items.push({ name: productsBuilder.itinerary, id: 101 })
		}
		reservationDetails?.product_items?.forEach(element => {
			const elemetID = element?.item?.id
			switch (elemetID) {
				case itineraryElementsIds.external:
					items.push({ name: inventory.messages.flights, id: elemetID })
					break;
				case itineraryElementsIds.hotel:
					items.push({ name: inventory.messages.hotels, id: elemetID })
					break;
				case itineraryElementsIds.sightseeing:
					items.push({ name: productsBuilder.attraction, id: elemetID })
					break;
				default:
					break;
			}
		});

		items = [...new Map(items.map(item => [item['name'], item])).values(), { name: productsBuilder.packagePricing, id: 102 }];
		return items;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reservationDetails?.product_items]);

	const ExternalElements = () => {
		//To Return uniqe External Element
		let externalElementAirLines = reservationDetails?.product_items?.filter(
			(element) => element?.item?.id === itineraryElementsIds.external
		);
		let uniqueAirLinesIds = {};
		const uniqueExtrenalAirLines = externalElementAirLines?.filter((item) => {
			const airLineId = item?.itemable?.transporter?.id;
			if (uniqueAirLinesIds[airLineId] === undefined) {
				uniqueAirLinesIds = { ...uniqueAirLinesIds, [airLineId]: item };
				return item;
			} else {
				return null
			}
		});
		if (uniqueExtrenalAirLines?.length > 0) {
			return uniqueExtrenalAirLines?.map((externalTransport) => {
				return (
					<Company
						key={externalTransport.id}
						title={marketPlace.airlines}
						name={externalTransport?.itemable?.transporter?.name}
						logo={externalTransport?.itemable?.transporter?.logo}
					/>
				);
			});
		} else {
			return null;
		}
	};

	const getDaysWithPackagesIdsList = async (packageSiblingsList) => {
		const packagesDaysIist = packageSiblingsList?.map(item => ({
			name: moment(item?.departure_date_at).format('DD-MM-YYYY'),
			id: item?.product_uuid,
			endDate: moment(item?.return_date_at).format('DD-MM-YYYY'),
			duration: item?.duration
		}))
		setDaysList(packagesDaysIist)
	}

	useEffect(() => {
		async function fetchPackageDetails() {
			const res = await fetchMarketPackagesDetails(uuid);
			setReservationDetails(res?.data);
			getDaysWithPackagesIdsList(res?.data?.package_siblings)
		}
		if (uuid) {
			fetchPackageDetails();
		}
	}, [uuid]);

	const packageContainHotel = productItemsTabs.filter(item => item?.id === itineraryElementsIds.hotel).length > 0;

	// calc
	const calcTotalPrices = useMemo(() => {
		let total = 0;
		if (packageContainHotel) {
			const productPriceBreakDown = reservationDetails?.product_price?.product_price_type?.id === 0;
			const productPriceFullPackage = reservationDetails?.product_price?.product_price_type?.id === 1;

			if (productPriceBreakDown) {
				total = calculateBreakdownPackage({ hotelRooms: hotelsSharedRooms, paxCount, reservationCagetory: categoryType?.value, reservationDetails })
			}
			if (productPriceFullPackage) {
				total = calculateFullPackage({ hotelRooms: hotelsSharedRooms, reservationCagetory: categoryType?.value, reservationDetails })
			}
		} else {
			let totalAdultPrices = +paxCount.adultPax * reservationDetails?.product_price_combinations[0]?.breakdown_price_after_discount;
			let totalchildrenPrices = +paxCount.childsPax * reservationDetails?.product_price_combinations[0]?.child_price_after_discount;
			total = totalAdultPrices + totalchildrenPrices;
		}

		return total || 0;

	}, [categoryType?.value, hotelsSharedRooms, packageContainHotel, paxCount, reservationDetails]);


	useEffect(() => {
		window.scrollTo(0, 0);
		const observer = new IntersectionObserver(([entry]) => {
			setIsNavDisappeared(entry.isIntersecting);
		},
			{ rootMargin: "-74px" }
		);
		observer.observe(document.querySelector("#root"));
		return () => observer.disconnect();
	}, []);



	return (
		<div className='package-details-container market-place-packages'>
			<ul className='packages-navigation' style={{ top: isNavDisappeared ? "74px" : "0" }} >
				{productItemsTabs?.map(tab => {
					return (
						<li key={`tab-${tab.id}`} className={`${+activeTab === tab.id ? "active" : ""} `}
							onClick={() => {
								setActiveTab(tab.id);
								scrollToElement(`tab-${tab.id}`)
							}}
						>
							{tab.name}
						</li>
					)
				})}
			</ul>

			{/* Packages Date Filter  */}
			<div className='packages-dates-filter' style={{ top: isNavDisappeared ? "150px" : "74px" }} >
				<h2 className='package-title'>{productsBuilder.packageDate}</h2>

				<div className="main-label mx-2 col-6 col-md-2 controls-field">
					{reservationDetails?.package_siblings &&
						<SelectField
							hasLabel={false}
							// placeholder={productsBuilder.enterUsers}
							placeholder={initialStartDate}
							options={daysList}
							value={selectedDate?.name}
							onChange={(e) => {
								console.log(e)
								setSelectedDate(e)
								history.push(`/${status}/package-details/${e?.id}`);
							}}
						/>
					}
				</div>

				{(selectedDate?.endDate || initialEndDate) &&
					<div className='date'>
						<p >
							{selectedDate?.endDate ? selectedDate?.endDate : initialEndDate}
						</p>
					</div>
				}

				{(selectedDate?.duration || initialDuration) &&
					<div className='date'>
						<p>
							{selectedDate?.duration ? selectedDate?.duration : initialDuration}  {productsBuilder?.days}
						</p>
					</div>
				}

				<button
					className="btn width-10 view-price"
					onClick={() => scrollToElement('tab-102')}
				>
					<DollarCircleIcon />
					<span className='mx-1'>{packages.viewPrice}</span>
				</button>

			</div>

			{/* content */}
			<div >
				<div className="row">
					<div className="col-lg-9 col-12">
						{/* Itinerary */}
						{reservationDetails?.itinerary?.length > 0 ?
							<div className='package-item' id="tab-101">
								<h2 className='package-title'>{productsBuilder.itinerary}</h2>
								<ProductItinerary
									itineraryData={reservationDetails?.itinerary}
									itineraryElementsIds={itineraryElementsIds}
								/>
							</div>
							:
							null
						}
						{/* view package items */}
						<PackageItemsView reservationDetails={reservationDetails} />


						{/* Terms & conditions */}
						<div className='package-item'>
							<h2 className='package-title'>{packages.termsAndConditions}</h2>
							<div className='mt-4'>
								{reservationDetails?.share_terms ?? (
									<p className="text-muted text-center">{marketPlace.NotFoundData}</p>
								)}
							</div>
						</div>

						{/* Required Documents */}
						<div className='package-item required-docs'>
							<div className='d-flex align-items-center'>
								<RequiredDocIcon />
								<h2 className='package-title mx-2'>{productsBuilder.requiredDocuments}</h2>
							</div>
							<div className='mt-4'>
								{reservationDetails?.share_required_documents ?? (
									<p className="text-muted text-center">{marketPlace.NotFoundData}</p>
								)}
							</div>
						</div>

						{/* packages price */}
						{packageContainHotel ?
							<div className='package-item' id='tab-102'>
								<h2 className='package-title'>{productsBuilder.packagePricing}</h2>

								{reservationDetails && reservationDetails?.roomAvailabilities && reservationDetails?.roomAvailabilities.length > 0 ?
									<PackagePricing
										reservationDetails={reservationDetails}
										paxCount={paxCount}
										setPaxCount={setPaxCount}
										errors={errors}
										setErrors={setErrors}
										categoryType={categoryType}
										setCategoryType={setCategoryType}
									>
										{categoryType?.id === "private" &&
											<div className="mt-3">
												<GuestsPerRoom
													productDetails={reservationDetails}
													hotelsPrivateRooms={hotelsPrivateRooms}
													setHotelsPrivateRooms={setHotelsPrivateRooms}
													totalAdultCount={paxCount?.adultPax}
													totalChildrenCount={paxCount?.childsPax}
													isMarketPlace={true}
												/>
											</div>
										}

										{categoryType?.id === "shared" &&
											<div className="mt-3">
												<GuestsPerPax
													productDetails={reservationDetails}
													hotelsSharedRooms={hotelsSharedRooms}
													setHotelsSharedRooms={setHotelsSharedRooms}
													totalAdultCount={paxCount?.adultPax}
													totalChildrenCount={paxCount?.childsPax}
													isMarketPlace={true}
												/>
											</div>
										}
									</PackagePricing>
									:
									null
								}
							</div>
							:
							<div className='package-item' id='tab-102'>
								<h2 className='package-title'>{productsBuilder.packagePricing}</h2>
								<div className='d-flex mt-4 flex-wrap'>
									<div className=' col-md-5 col-12'>
										<TextField
											type="number"
											label={marketPlace.Numberpax}
											id={"adults-pax-count"}
											name={"adultPax"}
											max={+reservationDetails?.remaining_adults_pax}
											value={paxCount.adultPax}
											onChange={(e) => {
												setPaxCount({ ...paxCount, adultPax: e.target.value > 0 ? Number(e.target.value).toString() : 0 });
												setErrors({
													...errors,
													...validate(e.target, {
														required: true,
														maxNumber: +reservationDetails?.remaining_adults_pax,
													}),
												})
											}}
											color={errors.adultPax?.required || errors.adultPax?.maxNumber ? "danger" : ""}
											errors={errors.adultPax}
										/>
									</div>
									{/* children pax */}
									<div className='col-md-5 col-12'>
										<TextField
											type="number"
											label={marketPlace.NumberChildrenpax}
											id={"children-pax-count"}
											name={"childsPax"}
											max={+reservationDetails?.remaining_children_pax}
											value={paxCount.childsPax}
											onChange={(e) => {
												setPaxCount({ ...paxCount, childsPax: e.target.value > 0 ? Number(e.target.value).toString() : 0 });
												setErrors({
													...errors,
													...validate(e.target, {
														maxNumber: +reservationDetails?.remaining_children_pax,
													}),
												})
											}}
											color={errors.childsPax?.maxNumber ? "danger" : ""}
											errors={errors.childsPax}
										/>
									</div>
								</div>

							</div>
						}


						<div className='d-flex justify-content-between align-items-center mt-4 p-4 bg-white' style={{ borderRadius: "12px" }}>
							<p>
								<span>{productsBuilder.totalPrice}</span>
								<span className='mx-2 text-success font-weight-bold'>
									{formatPrice(calcTotalPrices)} {" "}
									{reservationDetails?.currency}
								</span>
							</p>
							<button className='btn checkout-btn' onClick={CheckAvailability}
								disabled={
									(
										+paxCount.adultPax === 0 || +paxCount.adultPax > +reservationDetails?.remaining_pax ||
										+paxCount.childsPax > +reservationDetails?.remaining_pax
									)
								}
							>
								{packages.compeleteCheckout}
							</button>
						</div>


					</div>

					{!isMobile && (
						<div className="col-lg-3 col-12 mt-4">
							<>
								<Company
									title={packages.travelAgent}
									name={reservationDetails?.company_name?.[locale]}
									logo={
										reservationDetails?.company_avatar &&
										`${imageUrl}${reservationDetails?.company_avatar}`
									}
								/>
								{ExternalElements()}
								{/* <button
									className="btn w-100 view-price"
									onClick={() => scrollToElement('tab-102')}
								>
									<DollarCircleIcon />
									<span className='mx-1'>{packages.viewPrice}</span>
								</button> */}
							</>
						</div>
					)}


				</div>
			</div>
		</div >
	);
};


