import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/v1";
const serviceBuilderURL = URL + "/";

export const getAllServicesList = async (params) => {
  return await axios.get(
    `${serviceBuilderURL}builder-services/main`,
    { params }
  );
};

export const deleteServiceById = async (id) => {
  return await axios.delete(
    `${serviceBuilderURL}builder-services/main/${id}`,
  );
};

export const addHotelService = async (data) => {
  return await axios.post(`${serviceBuilderURL}builder-services/hotels`, data);
};


export const addFlightService = async (data) => {
  return await axios.post(`${serviceBuilderURL}builder-services/flights`, data);
};