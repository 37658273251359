import domesticFlight from " ../../assets/images/icons/domesticFlight.svg";
import Model from "components/model";
import { useProductsBuilderDispatch } from "context/productsBuilder";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Col } from "reactstrap";
import {
	cloneProduct,
	deleteProduct,
	downloadtripDocuments,
	downloadtripDocumentsexcel,
	downloadtripoperation,
	downloadtriptravelers,
	generateStatementFromProduct,
	unpublishedProduct,
} from "services/productbuilder";
import Locale from "../../../translations";
import { store } from "react-notifications-component";
import SharePackageModal from "./SharePackageModal";
import ShowForPermission from "helpers/showForPermission";
import { BusIcon } from "../Accommodations/Buses/Icons";
import VisaIcon from "../Pricing/IconsSVG/VisaIcon";
import ExternalIcon from "../Pricing/IconsSVG/ExternalIcon";
import AttractionIcon from "../Pricing/IconsSVG/AttractionIcon";
import ServiecIcon from "../Pricing/IconsSVG/ServiceIcon";
import InsuranceIcon from "../Pricing/IconsSVG/InsuranceIcon";
import PenIcon from "../Pricing/IconsSVG/PenIcon";
import StarIcon from "../Pricing/IconsSVG/StarIcon";
import DoneIcon from "../Pricing/IconsSVG/DoneIcon";
import MoreIcon from "../Pricing/IconsSVG/MoreIcon";
import DeleteIcon from "../Pricing/IconsSVG/DeleteIcon";
import DuplicateIcon from "../Pricing/IconsSVG/DuplicateIcon";
import PublishIcon from "../Pricing/IconsSVG/PublishIcon";
import ViewIcon from "../Pricing/IconsSVG/ViewIcon";
import { ReactComponent as HotelIcon } from "assets/images/hotelAccommodation/hotel.svg";
import { ExportToPdf } from 'modules/UmrahOperations/shared/Icons';
import { ReactComponent as DownloadIcon } from "assets/images/umrah-operations/download-cloud.svg";
import { capitalizeName, downloadDocument, formatDate, generatePDF } from 'helpers/utils';
import jsPDF from 'jspdf';
import { DefinitVoucher } from "components/Printing/DefinitVoucher";
import SafaLogo from "assets/images/MarketInvoice/SafaLogo.png";
import { Operations } from "components/Printing/TripBuilder/operations"
import { Borders, BridgeInspection, Palestine, Travellers } from 'components/Printing/TripBuilder/travellers';
import { fetchCompanyProfile } from 'services/profile';
export default function ProductsTableRow({
	product,
	fetchProducts,
	setisOpen,
	isOpen,
	setisOpenPrintPdf,
	isOpenPrintPdf,
	setisOpenPrintExcel,
	isOpenPrintExcel,
	isOpenPrintTravellers,
	setisOpenPrintTravellers,
	isOpenPrintDropdown,
	setisOpenPrintDropdown
}) {
	const { productsBuilder, productsList, inventory } = Locale;
	const iraqTypes = ["awqaf_iraq", "iraq_land"];

	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	// const dispatch = useDispatch();
	const dispatch = useProductsBuilderDispatch();
	const history = useHistory();
	const location = useLocation();
	const [modal, setModal] = useState({
		isOpen: false,
		type: "",
		title: "",
		active: false,
	});
	const [companyData, setCompanyData] = useState();





	const unpublished = async () => {
		const res = await unpublishedProduct(product.product_uuid);
		if (res.status === 200) {
			setModal({
				isOpen: !modal.isOpen,
				type: "Unpublished",
				title: "Unpublished",
			});
			store.addNotification({
				title: "Info!",
				message: productsList.UnpublishedMessages,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			fetchProducts();
		} else {
			store.addNotification({
				title: "Error!",
				message: "Error!",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	const cloneProducts = async () => {
		const res = await cloneProduct(product.product_uuid);
		if (res.status === 200 || res.status === 201) {
			store.addNotification({
				title: productsBuilder.Info,
				message: `${productsBuilder.clonesuccessfully}`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			fetchProducts();
		} else {
			store.addNotification({
				title: "Error!",
				message: "Error!",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	const toggle = () => {
		setisOpen(null)
		setModal({
			isOpen: !modal.isOpen,
			type: "delete",
			title: productsBuilder.deleteProduct,
		});
	};

	const toggleUnpublished = () => {
		setModal({
			isOpen: !modal.isOpen,
			type: productsBuilder.Unpublished,
			title: productsBuilder.Unpublished,
		});
	};

	let id = product.product_uuid;
	const deleteProducts = async () => {
		setModal({ ...modal, active: true });
		const res = await deleteProduct(id);
		if (res.status === 200) {
			setModal({});
			fetchProducts();
		} else {
			setModal({ ...modal, active: false, isOpen: false });
		}
	};
	const editProducts = async () => {
		localStorage.setItem("uuid", product.product_uuid);
		dispatch({ type: "saveBasicDetails", payload: { isEditMode: true } });
		history.push({
			pathname: `${location.pathname}/create`,
			search: location.search,
			state: {
				id,
			},
		});
	};
	const showProducts = async () => {
		localStorage.setItem("uuid", product.product_uuid);
		//dispatch({ type: "saveBasicDetails", payload: { isEditMode: true } });
		history.push({
			pathname: `${location.pathname}/show`,
			state: {
				id: id,
			},
			search: 'version=2'
		});
	};

	const moreActionsRef = useRef(null)


	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event) {
			if (moreActionsRef.current && !moreActionsRef.current.contains(event.target)) {
				setisOpen(null)
				setisOpenPrintPdf(null)
				setisOpenPrintExcel(null)
				setisOpenPrintTravellers(null)
				setisOpenPrintDropdown(null)

			}
		}
		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);
	// ----------------------------------------------------------------------------------------------------
	// Component Functions
	// ----------------------------------------------------------------------------------------------------
	const statusColor = () => {
		switch (product.product_status.id) {
			case 2:
				return "text-primary";
			case 1:
				return "text-warning";
			case 3:
				return "text-success";
			default:
				break;
		}
	};

	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------
	//
	const [shareModelOpen, setShareModelOpen] = useState(false);

	function toggleShareModel() {
		setShareModelOpen(!shareModelOpen);
	}

	async function convertTripToStatement(productId) {
		const res = await generateStatementFromProduct({ product_id: productId });
		if (res.status === 200 || res.status === 201) {
			store.addNotification({
				title: productsBuilder.Info,
				message: `converted successuly`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			fetchProducts();
		}
	}




	const downloadForTransporter = async (product, transporterName) => {
		const response = await downloadtripDocuments(product.id, transporterName);
		const fileName = `trip_data_${product.id}_${transporterName}.xlsx`;
		downloadDocument(response, fileName);
	};

	const downloadForExcel = async (product, type) => {
		const response = await downloadtripDocumentsexcel(product.id, type);
		const fileName = `trip_data_${product.id}_${type}.xlsx`;
		downloadDocument(response, fileName);
	};



	const getValueOrPlaceholder = (value) => (value ? value : "-----");

	const printPdfOperations = async (product) => {
		const res = await downloadtripoperation(product.id);
		let respondData = res.data.data;

		let respos = Operations;


		const keyValuePair = {
			"[booking_number]": getValueOrPlaceholder(respondData.id),
			"[pax]": getValueOrPlaceholder(respondData.pax),
			"[agent_name]": getValueOrPlaceholder(respondData.company_name?.en),
			"[arrival_date]": getValueOrPlaceholder(formatDate(respondData.departure_date_at)),
			"[program_duration]": getValueOrPlaceholder(respondData.trip_period),
			"[child_count]": getValueOrPlaceholder(respondData.children_travelers),
			"[adult_count]": getValueOrPlaceholder(respondData.adults_travelers),
			"[SafaLogo]": getValueOrPlaceholder(SafaLogo),
			"[return_date]": getValueOrPlaceholder(formatDate(respondData.return_date_at)),
			"[departure_port]": getValueOrPlaceholder(respondData.itinerary[2]?.items[0]?.itemable?.from_port?.name),
			"[carrier_company]": getValueOrPlaceholder(respondData.itinerary[2]?.items[0]?.itemable?.transporter?.name),
			"[departure_date]": getValueOrPlaceholder(formatDate(respondData.itinerary[2]?.items[0]?.itemable?.departure_at)),
			"[arrival_port]": getValueOrPlaceholder(respondData.itinerary[0]?.items[0]?.itemable?.to_port?.name),
			"[arrival_carrier_company]": getValueOrPlaceholder(respondData.itinerary[0]?.items[0]?.itemable?.transporter?.name),
			"[arrival_info_date]": getValueOrPlaceholder(formatDate(respondData.departure_date_at)),
		};

		for (const key in keyValuePair) {
			respos = respos.replace(key, keyValuePair[key]);
		}

		let reservations = "";
		respondData.reservations_travellers.forEach(traveller => {
			reservations += `
							<tr>
									<td>${getValueOrPlaceholder(traveller?.passport_number)}</td>
									<td>${getValueOrPlaceholder(traveller?.first_name)} ${getValueOrPlaceholder(traveller?.last_name)}</td>
									<td>${traveller?.gender?.toLowerCase() === 'f' ? 'Female' : traveller?.gender?.toLowerCase() === 'm' ? 'Male' : '-----'}</td>
									<td>${getValueOrPlaceholder(traveller?.nationality?.name)}</td>
									<td>-----</td>
										<td>${getValueOrPlaceholder(traveller?.age)}</td>
							</tr>`;
		});

		respos = respos.replace("[reservations]", reservations);

		let allRooms = "";
		if (respondData.product_items && respondData.product_items.length > 0) {
			allRooms = respondData.product_items
				.filter(item => item.itemable && item.itemable.hotel)
				.map(item => {
					const hotel = item.itemable;
					return `
					<tr>
						<td>${getValueOrPlaceholder(hotel.city_name)}</td>
						<td>${getValueOrPlaceholder(hotel.hotel?.name)}</td>
						<td>${getValueOrPlaceholder(formatDate(hotel.check_in_at))}</td>
						<td>${getValueOrPlaceholder(formatDate(hotel.check_out_at))}</td>
						<td>${getValueOrPlaceholder(hotel.night_count)}</td>
					</tr>
				`;
				})
				.join('');
		}

		respos = respos.replace("[allRooms]", allRooms);

		var popupWin = window.open('', ' ', 'top=0,left=0,height=100%,width=auto');
		popupWin.document.open();
		popupWin.document.write(`
					<html>
					<head>
					<title> Print Operations</title>
					<style>
					@page {
						size: A4;
						margin: 15px;			
						border: 1px solid black;
					}
					</style>
					</head>
					<body onLoad="{()=>window.print()}">${respos}</body>
					</html>`);
		popupWin.document.close();

		setTimeout(() => {
			popupWin.print();
		}, 100);
	};


	const printPdfTravellers = async (product) => {
		const res = await downloadtriptravelers(product.id);
		let respondData = res.data.data;
	
		let respos = Travellers;


		const keyValuePair = {
			"[booking_number]": getValueOrPlaceholder(respondData[0]?.id),
			"[arrival_date]": getValueOrPlaceholder(formatDate(respondData[0]?.trip_departure_date)),
			"[SafaLogo]": getValueOrPlaceholder(SafaLogo),
		};

		for (const key in keyValuePair) {
			respos = respos.replace(key, keyValuePair[key]);
		}

		let reservations = "";
		respondData.forEach((traveller) => {
			reservations += `
							<tr>
					<td>${getValueOrPlaceholder(traveller?.first_name)} ${getValueOrPlaceholder(traveller?.last_name)}</td>
					<td>${getValueOrPlaceholder(traveller?.nationality?.name)}</td>
									<td>${getValueOrPlaceholder(traveller?.passport_number)}</td>
									<td>----</td>
									<td>${getValueOrPlaceholder(traveller?.passport_expiry)}</td>
									<td>----</td>

									<td>${traveller?.gender?.toLowerCase() === 'f' ? 'Female' : traveller?.gender?.toLowerCase() === 'm' ? 'Male' : '----'}</td>
									<td>----</td>
									<td>----</td>
							</tr>`;
		});

		respos = respos.replace("[reservations]", reservations);

		var popupWin = window.open('', ' ', 'top=0,left=0,height=100%,width=auto')
		popupWin?.document.open()
		popupWin?.document.write(`
				<html>
				<head>
				<title> print Travellers</title>
				<style>
				@page {

					size: A4;
					
					margin:15px;			
					border: 1px solid black;
					
					}
				</style>
				</head>
				<body onLoad="{()=>window.print()}">${respos}</body>
				</html>`)
		popupWin.document.close()

		setTimeout(() => {
			popupWin.print()
		}, 100)

	};


	const printPdfBridgeInspection = async (product) => {
		const res = await downloadtriptravelers(product.id);
		let respondData = res.data.data;
	

		let respos = BridgeInspection;


		const keyValuePair = {
			"[booking_number]": getValueOrPlaceholder(respondData[0]?.id),
			"[arrival_date]": getValueOrPlaceholder(formatDate(respondData[0]?.trip_departure_date)),
			"[SafaLogo]": getValueOrPlaceholder(SafaLogo),
		};

		for (const key in keyValuePair) {
			respos = respos.replace(key, keyValuePair[key]);
		}

		let reservations = "";
		respondData.forEach((traveller) => {
			reservations += `
							<tr>
					<td>${getValueOrPlaceholder(traveller?.first_name)} ${getValueOrPlaceholder(traveller?.last_name)}</td>
					<td>${getValueOrPlaceholder(traveller?.nationality?.name)}</td>
									<td>${getValueOrPlaceholder(traveller?.passport_number)}</td>
									<td>${getValueOrPlaceholder(traveller?.birth_date)}</td>
									<td>----</td>

									<td>${traveller?.gender?.toLowerCase() === 'f' ? 'Female' : traveller?.gender?.toLowerCase() === 'm' ? 'Male' : '----'}</td>
									<td>----</td>
									<td>----</td>
																		<td>----</td>

							</tr>`;
		});

		respos = respos.replace("[reservations]", reservations);

		var popupWin = window.open('', ' ', 'top=0,left=0,height=100%,width=auto')
		popupWin?.document.open()
		popupWin?.document.write(`
				<html>
				<head>
				<title> print Travellers</title>
				<style>
				@page {

					size: A4;
					
					margin:15px;			
					border: 1px solid black;
					
					}
				</style>
				</head>
				<body onLoad="{()=>window.print()}">${respos}</body>
				</html>`)
		popupWin.document.close()

		setTimeout(() => {
			popupWin.print()
		}, 100)

	};

	const printPdfBorders = async (product) => {
		const res = await downloadtriptravelers(product.id);
		let respondData = res.data.data;
	

		// let respos = Travellers;
		let respos = Borders;


		const keyValuePair = {
			"[booking_number]": getValueOrPlaceholder(respondData[0]?.id),
			"[arrival_date]": getValueOrPlaceholder(formatDate(respondData[0]?.trip_departure_date)),
			"[SafaLogo]": getValueOrPlaceholder(SafaLogo),
		};

		for (const key in keyValuePair) {
			respos = respos.replace(key, keyValuePair[key]);
		}

		let reservations = "";
		respondData.forEach((traveller) => {
			reservations += `
							<tr>
					<td>${getValueOrPlaceholder(traveller?.first_name)} ${getValueOrPlaceholder(traveller?.last_name)}</td>
					<td>${getValueOrPlaceholder(traveller?.nationality?.name)}</td>
									<td>${getValueOrPlaceholder(traveller?.passport_number)}</td>
								<td>----</td>
									<td>${getValueOrPlaceholder(traveller?.passport_expiry)}</td>

									<td>${traveller?.gender?.toLowerCase() === 'f' ? 'Female' : traveller?.gender?.toLowerCase() === 'm' ? 'Male' : '----'}</td>
								
									<td>----</td>
									<td>----</td>

							</tr>`;
		});

		respos = respos.replace("[reservations]", reservations);

		var popupWin = window.open('', ' ', 'top=0,left=0,height=100%,width=auto')
		popupWin?.document.open()
		popupWin?.document.write(`
				<html>
				<head>
				<title> print Travellers</title>
				<style>
				@page {

					size: A4;
					
					margin:15px;			
					border: 1px solid black;
					
					}
				</style>
				</head>
				<body onLoad="{()=>window.print()}">${respos}</body>
				</html>`)
		popupWin.document.close()

		setTimeout(() => {
			popupWin.print()
		}, 100)

	};


	const printPdfPalestine = async (product) => {
		const res = await downloadtriptravelers(product.id);
		let respondData = res.data.data;
	

		let respos = Palestine;


		const keyValuePair = {
			"[booking_number]": getValueOrPlaceholder(respondData[0]?.id),
			"[arrival_date]": getValueOrPlaceholder(formatDate(respondData[0]?.trip_departure_date)),
			"[SafaLogo]": getValueOrPlaceholder(SafaLogo),
		};

		for (const key in keyValuePair) {
			respos = respos.replace(key, keyValuePair[key]);
		}

		let reservations = "";
		respondData.forEach((traveller) => {
			reservations += `
							<tr>
					<td>${getValueOrPlaceholder(traveller?.first_name)} ${getValueOrPlaceholder(traveller?.last_name)}</td>
					<td>${getValueOrPlaceholder(traveller?.nationality?.name)}</td>
					<td>----</td>
														<td>${getValueOrPlaceholder(traveller?.birth_date)}</td>
					<td>----</td>
					<td>----</td>
					<td>----</td>

									<td>${getValueOrPlaceholder(traveller?.passport_number)}</td>
								

							</tr>`;
		});

		respos = respos.replace("[reservations]", reservations);

		var popupWin = window.open('', ' ', 'top=0,left=0,height=100%,width=auto')
		popupWin?.document.open()
		popupWin?.document.write(`
				<html>
				<head>
				<title> print Travellers</title>
				<style>
				@page {

					size: A4;
					
					margin:15px;			
					border: 1px solid black;
					
					}
				</style>
				</head>
				<body onLoad="{()=>window.print()}">${respos}</body>
				</html>`)
		popupWin.document.close()

		setTimeout(() => {
			popupWin.print()
		}, 100)

	};



	useEffect(() => {
		async function fetchData() {
			const company = await fetchCompanyProfile();
		

			setCompanyData(company);
		}
		fetchData();
		


	}, []);


	return (
		<>
			<Model
				isOpen={modal.isOpen}
				toggle={toggle}
				type={modal.type}
				title={modal.title}
				submit={modal.type === "delete" ? deleteProducts : unpublished}
				disabled={modal.active}
			>
				{modal.type === "delete" ? (
					<Col className="p-3">{productsBuilder.deleteProductQoute}</Col>
				) : (
					<Col className="p-3">{productsBuilder.ProductUnpublished}</Col>
				)}
			</Model>

			{shareModelOpen && (
				<SharePackageModal
					shareModelOpen={shareModelOpen}
					toggleShareModel={toggleShareModel}
					product_uuid={product.product_uuid}
					validation_date_from_at={product.validation_date_from_at}
					validation_date_to_at={product.validation_date_to_at}
					fetchProducts={fetchProducts}
					is_break_down={product.is_break_down}
					product_type={product.product_type}
				/>
			)}

			<tr className="td-TB" key={product.product_uuid}>
				<td>
					<span className="text-primary">#{product.id}</span>
				</td>
				<td>
					<p className="tb-date">{product?.name}</p>
					<p className="tb-date-s">{product?.product_type?.name}</p>
				</td>
				<td>
					<p className="tb-date">{moment(product.created_at).format("ll")}</p>
				</td>
				<td>
					{product.product_classification ? (
						<p className="ele-round">
							<StarIcon />
							{product.product_classification.id}
						</p>
					) : (
						""
					)}
				</td>
				<ShowForPermission permission="Manage-Trip-Management-Module">
					<td>
						{product.is_has_price ? (
							<p className="ele-round">
								<DoneIcon />
								<span>{productsBuilder.done}</span>
							</p>
						) : (
							<button

								className={`bg-transparent border-0 ${product.items.length === 0 ? "opacity-5" : ""} `}
								disabled={product.items.length === 0}
								onClick={() => {
									localStorage.setItem("uuid", product.product_uuid);
									dispatch({
										type: "saveBasicDetails",
										payload: { isEditMode: true },
									});
									history.push({
										pathname: `${location.pathname}/create`,
										search: location.search,
										state: {
											id,
											tab: "pricing"
										},
									});
								}}

							>
								<span className="add-price-underline">
									{productsBuilder.addPrice}
								</span>
							</button>
						)}
					</td>
				</ShowForPermission>
				<td className=" ">
					{product.product_items.external_transportation ? (
						<p className="ele-round d-inline-block">
							<ExternalIcon size={24} />
						</p>
					) : (
						""
					)}
					{product.product_items.internal_transportation ? (
						<p className="ele-round d-inline-block">
							<BusIcon fillColor={"#707170"} size={24} />
						</p>
					) : (
						// <img src={internalGold} alt="" srcset="" />

						""
					)}
					{product.product_items.domestic ? (
						<p className="ele-round d-inline-block">
							<img src={domesticFlight} alt="" srcset="" />
						</p>
					) : (
						""
					)}
					{product.product_items.visa ? (
						<p className="ele-round d-inline-block">
							<VisaIcon size={24} />
						</p>
					) : (
						""
					)}
					{product.product_items.insurance ? (
						<p className="ele-round d-inline-block">
							<InsuranceIcon size={24} />
						</p>
					) : (
						""
					)}
					{product.product_items.sightseeing ? (
						<p className="ele-round d-inline-block">
							<AttractionIcon size={24} />
						</p>
					) : (
						""
					)}
					{product.product_items.hotel ? (
						<p className="ele-round d-inline-block">
							<HotelIcon size={24} />
						</p>
					) : (
						""
					)}
					{product.product_items.other_service ? (
						// <i className="fas fa-braille fa-fw"></i>
						<p className="ele-round d-inline-block">
							<ServiecIcon size={24} />
						</p>
					) : (
						""
					)}
				</td>
				<td>
					<p className="tb-date">
						{moment(product.validation_date_from_at).format("ll")}
					</p>
					<p className="tb-date-s">
						{moment(product.validation_date_to_at).format("ll")}
					</p>
				</td>
				<td>
					<p className="tb-date">
						{product.departure_date_at
							? moment(product.departure_date_at).format("ll")
							: null}
					</p>
					<p className="tb-date-s ">
						{product.return_date_at
							? moment(product.return_date_at).format("ll")
							: null}
					</p>
				</td>

				<ShowForPermission permission="Manage-Trip-Management-Module">
					<td>
						{product.product_status.name === "غير منشورة" ||
							product.product_status.name === "unpublished" ? (
							<>

								<p className="tb-unpublished">{product.product_status.name}</p>
							</>
						) : (
							<>

								<p className="tb-publish">{product.product_status.name}</p>
							</>
						)}

					</td>
				</ShowForPermission>

				<ShowForPermission permission="Manage-Trip-Management-Module">

					<td className="actions position-relative">

						<button
							className="btn"
							onClick={() => {
								setisOpen(product.id);
							}}
						>
							<MoreIcon />
						</button>

						{(product?.product_type?.name === "Umrah" ||
							product?.product_type?.name === "Umrah plus" ||
							product?.product_type?.name === "Hajj") && (
								<button
									className="btn"
									onClick={() => {
										setisOpenPrintDropdown(product.id);

									}}
								>
									<DownloadIcon />
								</button>
							)}
						{isOpenPrintDropdown == product.id && (
							<div style={{ position: "absolute", bottom: "15px", left: "-42px", zIndex: 6 }}>
								<div className="more-popup" ref={moreActionsRef}>

									{product?.product_items?.external_transportation && (
										<p
											className="border-top pointer"
											onClick={() => downloadForTransporter(product, "fly_egypt")}
										>
											<ExportToPdf color="#292D32" />
											<span className="mx-1">{inventory.messages.downloadDefault}</span>
										</p>
									)}
									{companyData?.country?.name === 'Iraq' ? (
										iraqTypes.map((transporterName) => (
											<p
												key={transporterName}
												className="border-top pointer"
												onClick={() => downloadForExcel(product, transporterName)}
											>
												<ExportToPdf color="#292D32" />
												<span className="mx-1">
													{inventory.messages.download} {capitalizeName(transporterName)}
												</span>
											</p>
										))
									) : companyData?.country?.name === 'Jordan' && (
										<p
											className="border-top pointer"
											onClick={() => downloadForExcel(product, "jordan")}
										>
											<ExportToPdf color="#292D32" />
											<span className="mx-1">{inventory.messages.download} Jordan</span>
										</p>
									)}
									{product?.product_items?.external_transportation && product.items.map((item) => (
										<p
											key={item.itemable.transporter.name}
											className="border-top pointer"
											onClick={() => downloadForTransporter(product, item.itemable.transporter.name)}
										>
											<ExportToPdf color="#292D32" />
											<span className="mx-1">
												{inventory.messages.download} {item.itemable.transporter.name}
											</span>
										</p>
									))}

								
									{companyData?.country?.name === 'Jordan' && (
										<>
											<p
												className="border-top pointer"
												onClick={() => printPdfTravellers(product)}
											>
												<ExportToPdf color="#292D32" />
												<span className="mx-1">{inventory.messages.downloadTravellers}</span>

											</p>


											<p
												className="border-top pointer"
												onClick={() => printPdfBridgeInspection(product)}
											>
												<ExportToPdf color="#292D32" />
												<span className="mx-1">{inventory.messages.bridgeCheck} </span>

											</p>


											<p
												className="border-top pointer"
												onClick={() => printPdfBorders(product)}
											>
												<ExportToPdf color="#292D32" />
												<span className="mx-1">{inventory.messages.borderCheck}</span>

											</p>


											<p
												className="border-top pointer"
												onClick={() => printPdfPalestine(product)}
											>
												<ExportToPdf color="#292D32" />
												<span className="mx-1">{inventory.messages.palestineCheck}</span>

											</p>
										</>
									)}


									<p
										className="border-top pointer"
										onClick={() => printPdfOperations(product)}
									>
										<ExportToPdf color="#292D32" />
										<span className="mx-1">{inventory.messages.downloadOperations}</span>
									</p>

								</div>
							</div>
						)}




						{isOpen == product.id && (
							<div style={{ position: "absolute", bottom: "15px", left: "-42px", zIndex: 6 }} >

								<div className="more-popup"
									ref={moreActionsRef}
								>
									<p
										title="Publish"
										role="button"
										onClick={() => {
											setisOpen(null)
											if (
												product?.product_status?.name === "غير منشورة" ||
												product?.product_status?.name === "unpublished"
											) {
												toggleShareModel();
											} else {
												toggleUnpublished();
											}
										}}
									>
										<PublishIcon />
										{product?.product_status?.id === 1 ? productsBuilder.Publish : productsBuilder.Unpublished}
									</p>
									<p
										title="clone"
										role="button"
										onClick={() => {
											setisOpen(null)
											cloneProducts();
										}}
									>
										<DuplicateIcon />
										{productsBuilder.Duplicate}
									</p>
									<p
										title="delete"
										className="border-top"
										role="button"
										onClick={toggle}
									>
										<DeleteIcon />
										{productsBuilder.delete}
									</p>
									{product?.product_items?.internal_transportation &&
										product?.has_reservation && (
											<p
												className="border-top pointer"
												onClick={() =>
													history.push(
														`${location.pathname}/${product?.product_uuid}/bus-accommodation`
													)
												}
											>
												<BusIcon fillColor="#ce7c0d" />
												{productsBuilder.busAccommodation}
											</p>
										)}
									{
										product?.has_reservation && (
											<p
												className="border-top pointer"

												onClick={() =>
													history.push(
														`${location.pathname}/hotel-accommodation/${product?.product_uuid}`
													)
												}
											>
												<HotelIcon fillColor="#ce7c0d" />
												{productsBuilder.hotelAccommodation}
											</p>
										)}
									{product?.is_has_price
										&& product?.product_items?.external_transportation
										&& product?.product_items?.hotel
										&& product?.product_type?.id === 2
										&& (
											<p className="border-top pointer" onClick={() => convertTripToStatement(product?.id)}>
												<PublishIcon />
												{"Convert to Operation Statements"}
											</p>
										)}
								</div>
							</div>
						)}
						{!product?.has_reservation ? (
							<button className="btn" title="edit" onClick={editProducts}>
								<PenIcon />
							</button>
						) : (
							<button
								className="edit-product"
								title="show"
								onClick={showProducts}
							>
								<ViewIcon />
							</button>
						)}


					</td>
				</ShowForPermission>
			</tr>
		</>
	);
}
