import React from 'react'
import CustomModal from 'modules/WebBuilder-V2/shared/CustomModal';
import TextField from 'components/Form/TextField/TextField';
import SelectField from 'components/Form/SelectField/SelectField';


const MENU_POSITIONS = [
  { id: "main-menu", name: "Main Menu" },
]
const PAGE_THEMES = [
  // { id: "#about-us", name: "About us" },
  { id: "destination", name: "Destination" },
]
// 

export default function AddEditMenuModal(props) {
  const { isOpen, type, selectedItem, togglePageDataModal, handleEditChanges, addItemsToMenu, mainMenu, pageContent, errors, languages } = props;
  console.log(mainMenu?.filter(item => item?.id !== "about-page"));
  return (
    <CustomModal
      isOpen={isOpen}
      toggle={togglePageDataModal}
      header={type === "Edit" ? "Edit Page" : "Add New Page"}
      size="lg"
    >
      <div className="d-flex flex-wrap">
        <div className="col-12">
          <TextField
            label={`Page URL`}
            id={`page-url`}
            name={`page-url`}
            value={selectedItem?.url}
            onChange={(e) => handleEditChanges(e.target.value, "url")}
          />
          <small className="control-field__feedback--danger error-message m-0">
            {errors?.url}
          </small>
        </div>

        {languages?.map(language => {
          return (
            <div className="col-md-6 col-12 mt-2" key={language?.code}>
              <TextField
                label={`Page Name (${language?.code})`}
                id={`page-${language?.code}-title`}
                name={`page-${language?.code}-title`}
                value={selectedItem?.title?.[language?.code]}
                onChange={(e) => handleEditChanges(e.target.value, "title", language?.code)}
              />
            </div>
          )
        })}

        {/* page sub from */}
        <div className="col-md-6 col-12 mt-2">
          <SelectField
            label="Sub From"
            id="page-sub-from"
            name="page-sub-from"
            options={mainMenu?.filter(item => item?.id !== "about-page")?.map(item => ({ ...item, name: item?.name?.["en"] || item?.name }))}
            value={selectedItem?.sub_from?.name}
            onChange={(e) => handleEditChanges(e, "sub_from")}
            disabled={
              (selectedItem?.subNavs && selectedItem?.subNavs?.length > 0)
              ||
              type === "Edit"
            }
          />
        </div>

        {/* page theme */}
        <div className="col-md-6 col-12 mt-2">
          <SelectField
            label="Page Theme"
            id="page-theme"
            name="page-theme"
            options={PAGE_THEMES}
            value={selectedItem?.page_theme?.name}
            onChange={(e) => handleEditChanges(e, "page_theme")}
            disabled={
              (selectedItem?.subNavs && selectedItem?.subNavs?.length > 0)
              ||
              type === "Edit"
            }
          />
        </div>

        {/* Menu Position */}
        <div className="col-md-6 col-12 mt-2">
          <SelectField
            label="Menu Position"
            id="menu-position"
            name="menu-position"
            options={MENU_POSITIONS}
            value={selectedItem?.menu_position?.name}
            onChange={(e) => handleEditChanges(e, "menu_position")}
          />
        </div>
      </div>

      <button className="primary-button mt-3"
        onClick={() => addItemsToMenu(selectedItem, pageContent)}
        disabled={
          !selectedItem?.url ||
          !selectedItem?.page_theme ||
          errors?.url
        }
      >
        {type} Page

      </button>

    </CustomModal>
  )
}
