/**
 * @Component TextAreaField
 * @Description This component for any text input group like text, email, etc.
 * @Props
 *    -> name: Name for text area field
 *    -> label: Text label for text area field
 *    -> placeholder: Text area field placeholder
 *    -> className: HTML classes for text area field
 *    -> id: HTML ID
 *    -> rows: number of textarea rows
 *    -> color: Bootstrap color
 *    -> value: Text area value
 *    -> feedbackMessage: Feedback message for validation
 *    -> onBlur: Text area Function
 *    -> onChange: Text area Function
 *    -> isInvalid: [Bool] set field is valid or not
 */

import { useState } from "react";
import Locale from "translations";

export default function TextAreaField(props) {
	const { commons } = Locale;

	const [foucs, setFoucs] = useState(false);



	const generateErrorsMsgs = (errors) => {
		let message = [];
		if (errors) {
			for (const [k, v] of Object.entries(errors)) {
				if (k === "required" && v) {
					message.push(commons.isRequired);
				} else if (k === "min" && v) {
					message.push(` should be at least ${props.min} chars`);
				} else if (k === "maxNumber" && v) {
					message.push(` should not exceed ${props.max}`);
				} else if (k === "minNumber" && v) {
					message.push(` should be at least ${props.min}`);
				} else if (k === "email" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "phone" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "number" && v) {
					message.push(commons.shouldBeNumber);
				}
			}
			if (message.length === 1) {
				return `${props.label} ${message[0]}`;
			} else if (message.length > 1) {
				return `${props.label} ${message.join(" & ")}`;
			} else {
				return;
			}
		}
	};

	return (
		<>
			{ props?.hasLabel &&
				<label
				className={`control-field__label ${
					props.color ? " control-field__label--" + props.color : ""
				} ${foucs ? "custom-select-text" : ""}`}
			>
				{props.label}
			</label>
			}
			<div
				className={`my-2 input-group control-field ${
					props.color ? "control-field--" + props.color : ""
				}  ${foucs ? "custom-control-field" : ""}`}
				style={{ height: props.height ?"auto" :"50px" }}
			>
				{/* Text Area Field Body */}

				<div className="control-field__body">
					{/* Label */}

					{/* Text Area */}
					<div className="row no-gutters">
						<textarea
							style={{ height: props.height ?props.height : "28px" }}
							name={props.name}
							className={`${props.className ?? ""} control-field__input w-100`}
							id={props.id}
							rows={props.rows}
							onChange={props.onChange}
							onBlur={() => {
								if (props.onBlur) {
									props.onBlur();
								}
								setFoucs(false);
							}}
							placeholder={props.placeholder}
							value={props.value}
							disabled={props.disabled}
							onFocus={() => {
								setFoucs(true);
							}}
						></textarea>
					</div>
				</div>
			</div>

			{/* Feedback */}

			<small
				className={`control-field__feedback control-field__feedback--${
					props.color
				} ${props.color ? "d-block" : null}  error-message`}
			>
				{generateErrorsMsgs(props.errors)}
			</small>

			<small
				className={`control-field__feedback control-field__feedback--${
					props.color
				} ${props.isInvalid ? "d-block" : "d-none"}`}
			>
				{props.feedbackMessage}
			</small>
		</>
	);
}
