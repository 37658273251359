import useShowPermission from 'hooks/useShowPermission';
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { paymentActionDone } from "services/productbuilder";
import Locale from "translations";

export default function PaymentAction(props) {
	const search = props.location.search;
	const name = new URLSearchParams(search);
	const [params, setParams] = useState({});
	const { tap } = useParams();

	const history = useHistory();
	const { payment, inventory } = Locale;
	const inventoryPermission = useShowPermission({
		permission: [
			"View-Inventory-Hotels",
			"Manage-Inventory-Hotels",
			"View-Inventory-Transfer",
			"Manage-Inventory-Transfer",
			"View-Inventory-Tours",
			"Manage-Inventory-Tours",
			"View-Inventory-Visa",
			"Manage-Inventory-Visa",
		],
	});

	useEffect(() => {
		let x = {};
		name.forEach(function (value, key) {
			x = { ...x, [key]: value };
		});
		setParams(x);
		pay(x);
	}, []);

	const pay = async (data) => {
		const response = await paymentActionDone(data, tap);

		if (response.status === 200 || response.status === "paid") {

			store.addNotification({
				title: inventory.messages.bookingSuccessfully,
				message: payment.messages.paymentSuccessful,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 2000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {


					if (response.pay_for === "wallet_recharge") {
						history.push("/wallet");

					} else if (response.pay_for === "hotelbeds_transfer_reservation") {
						history.push("/inventory/transfer");
					} else if (response.pay_for === "tour_booking" || response.pay_for === "tour_booking_pay_later" || response.pay_for.includes("tour")) {
						history.push("/inventory/tour");

					} else if (response.pay_for === "hotelbeds_reservation") {
						history.push("/inventory/hotels");
					} else if (response.pay_for === "online_visa_request") {

						if (response?.payload?.source == "tds") {
							sessionStorage.setItem("TdsData", JSON.stringify(response?.payload));
							history.push("/tds-visa/details");


							return
						}
						if (response?.data?.subject_id) {
							history.push("/inventory/visa-requests/edit/" + response?.data?.subject_id);
							return

						}

						if (response?.data?.subject_id) {
							history.push("/inventory/visa-requests/edit/" + response?.data?.subject_id);
							return

						}
						history.push("/inventory/visa-requests");
					} else if (response?.pay_for === "subscription_package") {
						history.push("/subscription/plans");
					} else {

						history.push("/successfullyPayment");
					}
					if (localStorage.getItem('toursCart')) {
						localStorage.removeItem('toursCart')
					}
				},
			});
		} else {





			if (response.data.pay_for === "umrah_holidays_reservation") {
				history.push(
					`/market-details/${response.data.data.payload.hotel_availability_cache_id}`
				);
			} else if (response?.data?.pay_for === "online_visa_request") {

				if (response?.data?.payload?.source == "tds") {
					history.push("/tds-visa")
				}

			} else {
				history.push(inventoryPermission ? "/inventory" : "/statistics");
			}
		}
	};
	return <div>Loading...</div>;
}
