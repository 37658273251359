import ServicesContent from "modules/WebBuilder/shared/ServicesContent";
import React, { useState } from "react";
import { AddElement } from "modules/WebBuilder/shared/AddElement";
import { ServiceCardDetails } from "modules/WebBuilder/shared/ServiceCardDetails";
import CustomCarousel from "modules/WebBuilder/shared/CustomCarousel";
import { useWebBuilderDispatch } from "context/webBuilder";
import { SORT_ITEMS_IN_CONTAINER_ACTION } from "../../../interfaces/blockTypes";
import { EditBox } from "modules/WebBuilder/shared/EditBox";
import DragAndDropContainer from "modules/WebBuilder/shared/DragAndDropContainer";
import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import AddService from "modules/WebBuilder/shared/AddService";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import Locale from 'translations';

const transferBlocksType = "transferBlocksType";

function TransferService({ details, onFocus, focusContainer }) {
	const { id, type, body, title, items } = details;
	const dispatch = useWebBuilderDispatch();
	const [openAdd, setOpenAdd] = useState(false);
	const [updateItem, setUpdateItem] = useState(null);
	const {webBuilder} = Locale;

	const handleOpenAddModal = () => {
		setOpenAdd((prev) => !prev);
		setUpdateItem(null)
	};
	const openEditModal = (item) => {
		handleOpenAddModal();
		setUpdateItem(item);
	};

	const editItem = (item, index) => {
		return [
			<span
				key={`${item.id}-${index}`}
				onClick={() => openEditModal({ ...item, index })}
				className="pointer-event"
			>
				<SettingIcon />
			</span>,
		];
	};
	const addItemToTransfer = (item) => {
		updateItem
			? dispatch({
					type: "editItemInContainer",
					payload: {
						id,
						item,
						itemIndex: updateItem.index,
					},
			  })
			: dispatch({
					type: "addItemToContainer",
					payload: {
						id,
						item,
					},
			  });
		setUpdateItem(null);
	};
	const updateTransfer = ({ name, value }) => {
		dispatch({
			type: "updateBodyContent",
			payload: {
				name,
				value,
				id,
			},
		});
	};

	const sortTransferItems = ({ dragIndex, hoverIndex }) => {
		dispatch({
			type: "sortItemsInContainer",
			payload: {
				id,
				dragIndex,
				hoverIndex,
			},
		});
	};

	const deleteBodyContent = ({ itemId, deleteType }) => {
		dispatch({
			type: "deleteBodyContent",
			payload: {
				id,
				deleteType,
				itemId,
			},
		});
	};
	const itemsBoxActions = (itemId) => {
		return [
			<span
				key={itemId}
				onClick={() =>
					deleteBodyContent({ itemId, deleteType: "container_item" })
				}
				className="pointer-event"
			>
				<TrashIcon />
			</span>,
		];
	};
	const selectedIds = items ? items.map((i) => i.id) : [];
	return (
		<EditBox editMode={focusContainer === type}>
			<div
				onClick={() => onFocus(type)}
				className="web-builder-content-tours container"
				id={id}
			>
				<div className="service-top-section">
					<ServicesContent
						header={title}
						body={body}
						changeHeader={(value) => updateTransfer({ value, name: "title" })}
						changebody={(value) => updateTransfer({ value, name: "body" })}
					/>
					<AddElement
						onClick={handleOpenAddModal}
						title={webBuilder.AddService}
						className={
							items?.length ? "bg-white border-0 flex-row height-90" : ""
						}
					/>
				</div>

				<div className="tours-detials">
					<CustomCarousel>
						{items.map((item, index) => (
							<DragAndDropContainer
								accept={transferBlocksType}
								type={transferBlocksType}
								id={item.id}
								index={index}
								action={SORT_ITEMS_IN_CONTAINER_ACTION}
								moveItemInContainer={(dragIndex, hoverIndex) =>
									sortTransferItems({ dragIndex, hoverIndex })
								}
								// onDrop={}
								key={item.id}
							>
								<EditBox
									editMode={focusContainer === type}
									actions={[
										...editItem(item, index),
										...itemsBoxActions(item.id),
									]}
								>
									<ServiceCardDetails {...item} itemType="transfer" />
								</EditBox>
							</DragAndDropContainer>
						))}
					</CustomCarousel>
				</div>
				{openAdd&&<AddService
					handleModalState={handleOpenAddModal}
					open={openAdd}
					item_id={2}
					label={"Service"}
					header={"Transfer"}
					onAdd={addItemToTransfer}
					details={updateItem}
					selectedIds={selectedIds}
				/>}
			</div>
		</EditBox>
	);
}

export default TransferService;
